import React, { Component } from 'react';
import { getApiData } from '../../../fetchApi'

class PayrollOnboardingStepFive extends Component {
    constructor(props) {
        super(props);
        this.state = {
            federalFrequency: '',
            federalForm: '',
            federalFilingAddress: '',
            federalWithholdingData: {},
            states: [],
            selectedState: '',
            stateWithholding: [],
            stateWithholdingData: {},
            ein: '',
            stateSUI: []
        };
    }

    componentDidMount = async() => {
        // Fetch data from APIs and update state
        var states = await getApiData("GET_STATES", [])
        var ein = await getApiData("GET_EIN_BY_GUID", [sessionStorage.getItem('x-params-2')])
        var keys = Object.keys(this.props.payrollOnboardingObj)
        var newOnboardingInfo = this.state
        for (var x = 0; x < keys.length; x++) {
            if (newOnboardingInfo[keys[x]] !== undefined) {
                newOnboardingInfo[keys[x]] = this.props.payrollOnboardingObj[keys[x]]
            }
        }
        this.setState({ states: states, ein:ein["ein"] });
    }

    trackPayrollStep = e => {
        var payrollOnboardInfo = this.state
        this.props.trackOnboardingStep(e.target.id, payrollOnboardInfo)
    }

    handleFederalFrequencyChange = (event) => {
        this.setState({ federalFrequency: event.target.value });
    };

    handleFederalFormChange = (event) => {
        this.setState({ federalForm: event.target.value });
    };

    handleChange = (e) => {
        this.setState({ federalFilingAddress: e.target.value })
    }

    handleStateChange = async (event) => {
        var stateId = event.target.value
        if (stateId === '')
            return;
        var stateWithholding = await getApiData("GET_STATE_WITHHOLDING", [stateId])
        var sui = await getApiData("GET_SUI", [stateId])
        this.setState({ selectedState: stateId, stateWithholding: stateWithholding, stateSUI: sui });
    };

    handleStateWithholdingChange = (event) => {
        var stateWithholdingData = this.state.stateWithholdingData
        stateWithholdingData[event.target.id] = event.target.value
        this.setState({ stateWithholdingData: stateWithholdingData })
    }



    render() {
        const { federalFrequency, federalForm, federalFilingAddress, states, selectedState, stateWithholding, stateSUI } = this.state;

        return (
            <div className="container mt-4">
                <div>
                    <label>EIN:</label>
                    <input type="text" className="form-control" readOnly value={this.state.ein} />
                </div>

                <div className="mt-3">
                    <label>How frequently are you submitting federal tax withholdings?</label>
                    <select className="form-select" value={federalFrequency} name="Frequency" onChange={this.handleFederalFrequencyChange}>
                        <option value="">Select frequency</option>
                        <option value="monthly">Monthly</option>
                        <option value="semi-monthly">Semi-Monthly</option>
                    </select>
                </div>

                <div className="mt-3">
                    <label>Federal filing form:</label>
                    <select className="form-select" value={federalForm} name="FilingForm" onChange={this.handleFederalFormChange}>
                        <option value="">Select form</option>
                        <option value="940">940</option>
                        <option value="941">941</option>
                    </select>
                </div>

                <div className="mt-3">
                    <label>Filing address:</label>
                    <input type="text" className="form-control" name="FilingAddress" onChange={this.handleChange} value={federalFilingAddress} />
                </div>

                {/* State withholding form */}
                <div className="mt-4">
                    <h3>State Withholding & SUI</h3>
                    <div className="mt-3">
                        <label>State:</label>
                        <select className="form-select" value={selectedState} onChange={this.handleStateChange}>
                            <option value="">Select state</option>
                            {states.length > 0 && states.map(state => (
                                <option key={state["id"]} value={state["id"]}>{state["state"]}</option>
                            ))}
                        </select>
                    </div>
                    {stateWithholding.map(withholding => (
                        <div>
                            <label>{withholding.withholding}</label>
                            <input type="text" id={withholding.withholding} onChange={this.handleStateWithholdingChange} className="form-control" />
                        </div>
                    ))}
                    {stateSUI.map(sui => (
                        <div>
                            <label>{sui.sui}</label>
                            <input type="text" id={sui.sui} onChange={this.handleStateWithholdingChange} className="form-control" />
                        </div>
                    ))}
                    
                </div>
                <br />
                <div className="row">
                    <div className="col-lg-2"><button id="continue" onClick={this.trackPayrollStep} className="btn btn-primary">Continue</button></div>
                    <div className="col-lg-2"><button id="back" className="btn btn-warning" onClick={this.trackPayrollStep}>Back</button></div>
                    <div className="col-lg-2"><button onClick={this.props.cancelOnboarding} className="btn btn-danger">Cancel</button></div>
                </div>
            </div>
        );
    }
}

export default PayrollOnboardingStepFive;