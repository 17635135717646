import React, { Component } from 'react';
import { getApiData, postApiData } from '../../fetchApi';
import {
    faEye,
    faEyeSlash
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import cloneDeep from 'lodash/cloneDeep';


export class CompensationInfo extends Component {

    constructor(props) {
        super(props)
        this.state = {
            apiData: null,
            editMode: false,
            editData: null,
            maskedData: {
                'baseCompensation': true,
                'targetAnnualBonus': true,
                'onTargetCommission': true,
                'offerLetterShareCount': true,
            }
        }
    }

    componentDidMount = async () => {
        this.fetchData()
    }


    fetchData = async () => {
        const data = await getApiData("GET_TEAM_MEMBER_COMPENSATION_DETAILS", [this.props.selectedTeamMemberId])
        this.setState({
            apiData: data
        })
    }

    setEditMode = (e) => {
        var data = this.state.apiData
        e.target.id === 'edit' ? this.setState({ editMode: true, editData: data }) : this.setState({ editMode: false, editData: null })
    }

    save = async (e) => {
        var editData = this.state.editData
        await postApiData("SAVE_TEAM_MEMBER_COMPENSATION_DETAILS", [editData[0]])
        this.setState({
            apiData: editData,
            editMode: false,
            editData: null
        })
    }

    updateData = (e) => {
        var data = this.state.editData === null ? cloneDeep(this.state.apiData) : cloneDeep(this.state.editData)
        data[0][e.target.id] = e.target.value
        this.setState({ editData: data })
    }

    toggleFieldVisibility = (e) => {
        var maskedData = this.state.maskedData;
        maskedData[e.currentTarget.id] = !maskedData[e.currentTarget.id]
        this.setState({maskedData:maskedData})
    }

    render() {
        return (
            this.state.apiData === null ? "" :
                <>
                    <h4>Compensation Information </h4>
                    <div className="row team-member-info-container">
                        <div className="col-lg-6 team-member-info-header">
                            Base Compensation:
                        </div>
                        <div className="col-lg-6">
                            {
                                this.state.editMode ?
                                    <input type="text" onBlur={this.updateData} id="baseCompensation" defaultValue={this.state.editData[0].baseCompensation}></input>
                                    :
                                    this.state.maskedData["baseCompensation"] ?
                                        <><div style={{ display: 'inline-block' }} >&#x2022;&#x2022;&#x2022;&#x2022;&#x2022;&#x2022;&#x2022;&#x2022;&#x2022;&nbsp;</div><div style={{ display: 'inline-block' }}><FontAwesomeIcon id="baseCompensation" onClick={this.toggleFieldVisibility} icon={faEye}/></div> </>
                                        : 
                                        <><div style={{ display: 'inline-block' }}> {this.state.apiData[0].baseCompensation} </div> <div style={{ display: 'inline-block' }}><FontAwesomeIcon id="baseCompensation" onClick={this.toggleFieldVisibility} icon={faEyeSlash}/></div> </>
                            }
                        </div>
                    </div>
                    <div className="row team-member-info-container">
                        <div className="col-lg-6 team-member-info-header">
                            Per:
                        </div>
                        <div className="col-lg-6">
                            {
                                this.state.editMode ?
                                    <input type="text" onBlur={this.updateData} id="per" defaultValue={this.state.editData[0].per}></input>
                                    :
                                    this.state.apiData[0].per
                            }
                        </div>
                    </div>
                    <div className="row team-member-info-container">
                        <div className="col-lg-6 team-member-info-header">
                            Target Annual Bonus:
                        </div>
                        <div className="col-lg-6">
                            {
                                this.state.editMode ?
                                    <input type="text" onBlur={this.updateData} id="targetAnnualBonus" defaultValue={this.state.editData[0].targetAnnualBonus}></input>
                                    :
                                    this.state.maskedData["targetAnnualBonus"] ?
                                        <><div style={{ display: 'inline-block' }} >&#x2022;&#x2022;&#x2022;&#x2022;&#x2022;&#x2022;&#x2022;&#x2022;&#x2022;&nbsp;</div><div style={{ display: 'inline-block' }}><FontAwesomeIcon id="targetAnnualBonus" onClick={this.toggleFieldVisibility} icon={faEye} /></div> </>
                                        :
                                        <><div style={{ display: 'inline-block' }}> {this.state.apiData[0].targetAnnualBonus} </div> <div style={{ display: 'inline-block' }}><FontAwesomeIcon id="targetAnnualBonus" onClick={this.toggleFieldVisibility} icon={faEyeSlash} /></div> </>
                            }
                        </div>
                    </div>
                    <div className="row team-member-info-container">
                        <div className="col-lg-6 team-member-info-header">
                            On-Target Commission:
                        </div>
                        <div className="col-lg-6">
                            {
                                this.state.editMode ?
                                    <input type="text" onBlur={this.updateData} id="onTargetCommission" defaultValue={this.state.editData[0].onTargetCommission}></input>
                                    :
                                    this.state.maskedData["onTargetCommission"] ?
                                        <><div style={{ display: 'inline-block' }} >&#x2022;&#x2022;&#x2022;&#x2022;&#x2022;&#x2022;&#x2022;&#x2022;&#x2022;&nbsp;</div><div style={{ display: 'inline-block' }}><FontAwesomeIcon id="onTargetCommission" onClick={this.toggleFieldVisibility} icon={faEye} /></div> </>
                                        :
                                        <><div style={{ display: 'inline-block' }}> {this.state.apiData[0].onTargetCommission} </div> <div style={{ display: 'inline-block' }}><FontAwesomeIcon id="onTargetCommission" onClick={this.toggleFieldVisibility} icon={faEyeSlash} /></div> </>
                            }
                        </div>
                    </div>
                    <div className="row team-member-info-container">
                        <div className="col-lg-6 team-member-info-header">
                            Offer Letter Share Count
                        </div>
                        <div className="col-lg-6">
                            {
                                this.state.editMode ?
                                    <input type="email" onBlur={this.updateData} id="offerLetterShareCount" defaultValue={this.state.editData[0].offerLetterShareCount}></input>
                                    :
                                    this.state.maskedData["offerLetterShareCount"] ?
                                    <><div style={{ display: 'inline-block' }} >&#x2022;&#x2022;&#x2022;&#x2022;&#x2022;&#x2022;&#x2022;&#x2022;&#x2022;&nbsp;</div><div style={{ display: 'inline-block' }}><FontAwesomeIcon id="offerLetterShareCount" onClick={this.toggleFieldVisibility} icon={faEye} /></div> </>
                                        :
                                    <><div style={{ display: 'inline-block' }}> {this.state.apiData[0].offerLetterShareCount} </div> <div style={{ display: 'inline-block' }}><FontAwesomeIcon id="offerLetterShareCount" onClick={this.toggleFieldVisibility} icon={faEyeSlash} /></div> </>
                            }
                        </div>
                    </div>
                    <div className="row team-member-info-container">
                        <div className="col-lg-6">
                            {
                                this.state.editMode ?
                                    <>
                                        <a id="save" style={{ marginRight: '10px' }} onClick={this.save} className="btn btn-primary">Save</a>
                                        <a id="cancel" onClick={this.setEditMode} className="btn btn-warning">Cancel</a>
                                    </>
                                    :
                                    <a id="edit" onClick={this.setEditMode} className="btn btn-primary">Edit</a>
                            }
                        </div>
                    </div>
                </>
        );
    }
}

export default CompensationInfo
