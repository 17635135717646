import React, { Component } from 'react';
import { Container } from 'reactstrap';
import { NavMenu } from './NavMenu';
import SideBar from "./SideMenuFolder/SideBar";
import { getApiData } from '../fetchApi';

export class Layout extends Component {
    static displayName = Layout.name;

    constructor(props) {
        super(props)
        this.state = {
            sidebarIsOpen: true,
            UserRoleId: null
        }
    }

    componentDidMount = async () => {
        var apiData = await getApiData("GET_USER_BY_GUID", [sessionStorage.getItem('x-params-1'), false])
        this.setState({UserRoleId: apiData[0].userRoleId})
    }

    toggleSideBar = () => {
        this.state.sidebarIsOpen ? this.setState({ sidebarIsOpen: false }) : this.setState({ sidebarIsOpen: false })
    }

    swapEntities = (entityGuid) => {
        this.props.swapEntities(entityGuid)
    }

  render() {
    return (
        <div>
            <NavMenu swapEntities={this.swapEntities} />
            <div className="row">
                <div className="col-lg-12">
                    <SideBar toggle={this.toggleSideBar} isOpen={this.state.sidebarIsOpen} userRoleId={this.state.UserRoleId} />
                </div>
            </div>
            <Container className="row margin-fix" tag="main">
                <div className="col-lg-12"> 
                   {this.props.children}
                </div> 
            </Container>
      </div>
    );
  }
}
