import React, { Component } from 'react';
export default class EntityCellRenderer extends Component {

    constructor(props) {

        super(props);

        this.state = {
            
        };

    }



    selectEntityDetails = (params) => {
        this.props.getEntityDetails(this.props.data.id)
    }



    render() {
        return (<a onClick={this.selectEntityDetails} className="payroll-text">{new Array(this.props.value)}</a>)
    }

}
