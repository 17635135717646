import React, { Component } from 'react';
import { getApiData } from '../../../fetchApi'
import SignatureCanvas from '../../TeamMemberContent/Onboarding/SignatureCanvas'

class PayrollOnboardingStepSix extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedTeamMemberId: 0,
            teamMembers: [],
            btnValue: "Use owner as signatory",
            dropDownValue: ''
        };
    }

    componentDidMount = async () => {
        var apiData = await getApiData("GET_TEAM_MEMBER_LIST", [sessionStorage.getItem('x-params-2')])
        var keys = Object.keys(this.props.payrollOnboardingObj)
        var newOnboardingInfo = this.state
        for (var x = 0; x < keys.length; x++) {
            if (newOnboardingInfo[keys[x]] !== undefined) {
                newOnboardingInfo[keys[x]] = this.props.payrollOnboardingObj[keys[x]]
            }
        }
        this.setState({ teamMembers: apiData });
    }

    getOwnerDetails = async () => {
        var apiData = await getApiData("GET_OWNERS_BY_ENTITY", [sessionStorage.getItem('x-params-2')])
        this.setState({ selectedTeamMemberId: apiData[0].id, dropDownValue: '', btnValue: "Owner has been set as signatory!" })

    }

    trackPayrollStep = e => {
        var payrollOnboardInfo = this.state
        this.props.trackOnboardingStep(e.target.id, payrollOnboardInfo)
    }

    handleChange = e => {
        this.setState({ selectedTeamMemberId: e.target.value, dropDownValue: e.target.value, btnValue: "Use owner as signatory" })
    }

    render() {
        return (
            <div className="container mt-4">
                <div>
                    <input type="button" onClick={this.getOwnerDetails} className="btn btn-primary" value={this.state.btnValue}></input>
                    <br /><br />
                    OR
                    <br /><br />
                    <label>Please select the signatory and have them sign</label>
                    <select className="form-select" value={this.state.dropDownValue} onChange={this.handleChange}>
                        <option value="">Select a signatory</option>
                        {this.state.teamMembers.map(teamMember => (
                            <option key={teamMember.id} value={teamMember.id}>{teamMember.fullName} - {teamMember.teamMemberDetails.workEmail}</option>
                        ))}
                    </select>

                    {this.state.dropDownValue != '' ?
                        <SignatureCanvas entityGuid={sessionStorage.getItem('x-params-2')} signatureType="_signatorySignature" guid={this.state.teamMembers.find(teamMember => teamMember.id == this.state.selectedTeamMemberId).guid}  ></SignatureCanvas>
                        : 
                        ""
                    }
                    
                </div>
                <br /><br />
                <div className="row">
                    <div className="col-lg-2"><button id="save" onClick={this.trackPayrollStep} className="btn btn-primary">Save</button></div>
                    <div className="col-lg-2"><button id="back" className="btn btn-warning" onClick={this.trackPayrollStep}>Back</button></div>
                    <div className="col-lg-2"><button onClick={this.props.cancelOnboarding} className="btn btn-danger">Cancel</button></div>
                </div>
            </div>
           

// Now `selectedTeamMember` contains the first element that matches the condition

        );
    }
}

export default PayrollOnboardingStepSix;