import React, { Component } from 'react';
import MainGridComponent from '../MainGridComponent'

export class PayrollDetails extends Component {

    constructor(props) {
        super(props)
        this.state = {
        }
    }


    render() {
        return (
            <>
                <a className="btn btn-primary" onClick={this.props.backToPayroll}>Back To Payroll</a> <br />
                <div>These are payroll details for payroll {this.props.payrollId}</div>
                <MainGridComponent payrollId={this.props.payrollId} pagetype="PayrollDetails"></MainGridComponent>
            </>
       );
    }
}

export default PayrollDetails
