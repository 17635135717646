import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faHomeUser,
    faPeopleGroup,
    faBuilding,
    faBars,
    faSackDollar,
    faFolderOpen,
    faGear,
} from "@fortawesome/free-solid-svg-icons";
import { NavItem, NavLink, Nav } from "reactstrap";
import classNames from "classnames";
import { Link } from "react-router-dom";

import SubMenu from "./SubMenu";

const SideBar = ({ isOpen, toggle, userRoleId }) => {
    const [activeMenuItem, setActiveMenuItem] = useState("Home");

    const handleMenuItemClick = (menuItem) => {
        setActiveMenuItem(menuItem);
    };

    return (
        <div style={{ display: "flex", justifyContent: "center" }}>
            <div className="side-menu">
                <Nav className="list-unstyled pb-3">
                    <NavItem>
                        <NavLink
                            className={classNames("navigation-item", {
                                active: activeMenuItem === "Home",
                            })}
                            tag={Link}
                            to={"/"}
                            onClick={() => handleMenuItemClick("Home")}
                        >
                            <FontAwesomeIcon icon={faHomeUser} className="mr-2" />
                            Home
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            className={classNames("navigation-item", {
                                active: activeMenuItem === "People",
                            })}
                            tag={Link}
                            to={"/people"}
                            onClick={() => handleMenuItemClick("People")}
                        >
                            <FontAwesomeIcon icon={faPeopleGroup} className="mr-2" />
                            People
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            className={classNames("navigation-item", {
                                active: activeMenuItem === "Company",
                            })}
                            tag={Link}
                            to={"/company"}
                            onClick={() => handleMenuItemClick("Company")}
                        >
                            <FontAwesomeIcon icon={faBuilding} className="mr-2" />
                            Company
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            className={classNames("navigation-item", {
                                active: activeMenuItem === "Payroll",
                            })}
                            tag={Link}
                            to={"/payroll"}
                            onClick={() => handleMenuItemClick("Payroll")}
                        >
                            <FontAwesomeIcon icon={faSackDollar} className="mr-2" />
                            Payroll
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            className={classNames("navigation-item", {
                                active: activeMenuItem === "Taxes",
                            })}
                            tag={Link}
                            to={"/taxes-and-compliance"}
                            onClick={() => handleMenuItemClick("Taxes")}
                        >
                            <FontAwesomeIcon icon={faBars} className="mr-2" />
                            Taxes & Compliance
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            className={classNames("navigation-item", {
                                active: activeMenuItem === "Reports",
                            })}
                            tag={Link}
                            to={"/reports"}
                            onClick={() => handleMenuItemClick("Reports")}
                        >
                            <FontAwesomeIcon icon={faFolderOpen} className="mr-2" />
                            Reports
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            className={classNames("navigation-item", {
                                active: activeMenuItem === "Settings",
                            })}
                            tag={Link}
                            to={"/settings"}
                            onClick={() => handleMenuItemClick("Settings")}
                        >
                            <FontAwesomeIcon icon={faGear} className="mr-2" />
                            Settings
                        </NavLink>
                    </NavItem>
                    {userRoleId === 1 && (
                        <NavItem>
                            <NavLink
                                className={classNames("navigation-item", {
                                    active: activeMenuItem === "Admin",
                                })}
                                tag={Link}
                                to={"/admin"}
                                onClick={() => handleMenuItemClick("Admin")}
                            >
                                <FontAwesomeIcon icon={faGear} className="mr-2" />
                                Admin
                            </NavLink>
                        </NavItem>
                    )}
                </Nav>
            </div>
        </div>
    );
};

export default SideBar;
