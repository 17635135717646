import { Tab } from 'bootstrap';
import React, { Component } from 'react';
import Select from 'react-select';
import { getApiData, postApiData } from '../../fetchApi';
import './../../custom.css'
import Papa from 'papaparse';
import { read, utils } from 'xlsx';
import { parse, format, isValid } from 'date-fns';

class BulkHiring extends Component {

    constructor(props) {
        super(props)
        this.state = {
            hiringStep: 0,
            guid: "",
            tableData: [{
                firstName: '',
                lastName: '',
                email: '',
                selectedWorkLocations: [],
                selectedEmploymentType: [],
                isExempt: false,
                manager: [],
                jobTitle: '',
                startDate: '',
                selectedDirectReports: [],
                selectedDepartments: [],
                salary: '',
                payType: [],
                upFrontPayments: '',
            }],
            workLocations: [],
            directReports: [],
            departments: [],
            payTypeOptions: [{ value: 'hourly', label: 'Hourly' },
                { value: 'daily', label: 'Daily' },
                { value: 'weekly', label: 'Weekly' },
                { value: 'monthly', label: 'Monthly' },
                { value: 'annually', label: 'Annually' },
            ],
            employmentTypes: [{ value: 'contractor', label: 'Contractor' },
            { value: 'salaried full-time', label: 'Salaried, full-time' },
            { value: 'hourly full-time', label: 'Hourly, full-time' },
            { value: 'hourly part-time', label: 'Hourly, part-time' },
            { value: 'temporary / intern', label: 'Temporary / Intern' },
            ]
        }
    }

    componentDidMount = async () => {
        // Fetch data for dropdowns (workLocations, managers, departments, directReports) from API
        var workLocationsData = await getApiData("GET_OFFICES", [sessionStorage.getItem("x-params-2")])
        var departmentsData = await getApiData("GET_DEPARTMENTS", [sessionStorage.getItem("x-params-2")])
        var directReportManagerData = await getApiData("GET_TEAM_MEMBER_LIST", [sessionStorage.getItem('x-params-2')])

        directReportManagerData = directReportManagerData.map(x => { return { value: x.fullName.toLowerCase(), label: x.fullName } })
        departmentsData = departmentsData.map(x => { return { value: x.departmentName.toLowerCase(), label: x.departmentName } })
        workLocationsData = workLocationsData.map(x => { return { value: x.officeName.toLowerCase(), label: x.officeName } })

        this.setState({
            departments: departmentsData,
            directReports: directReportManagerData,
            workLocations: workLocationsData,
        })
    }

    handleFileUpload = (event) => {
        const file = event.target.files[0];
        const reader = new FileReader();
        reader.onload = (e) => {
            const fileData = e.target.result;
            const extension = file.name.split('.').pop().toLowerCase();

            let parsedData;
            if (extension === 'csv') {
                parsedData = this.parseCSVFileData(fileData);
            } else if (['xls', 'xlsx'].includes(extension)) {
                parsedData = this.parseExcelFileData(fileData);
            } else {
                // Handle unsupported file types or display an error message
                return;
            }
            var tableData = this.buildBulkImportTable(parsedData);
            this.setState({ tableData: tableData });
        };
        reader.readAsBinaryString(file);
    };

    parseCSVFileData = (fileData) => {
        const parsedData = Papa.parse(fileData).data;
        return parsedData;
    };

    parseExcelFileData = (fileData) => {
        const workbook = read(fileData, { type: 'binary' });
        const worksheet = workbook.Sheets[workbook.SheetNames[0]];
        const parsedData = utils.sheet_to_json(worksheet, { header: 1 });
        return parsedData;
    };

    buildBulkImportTable = (parsedData) => {
        var { tableData } = this.state;
        for (var x = 1; x < parsedData.length; x++) {
            var dataRow = {
                firstName: '',
                lastName: '',
                email: '',
                selectedWorkLocations: [],
                selectedEmploymentType: [],
                isExempt: false,
                manager: [],
                jobTitle: '',
                startDate: '',
                selectedDirectReports: [],
                selectedDepartments: [],
                salary: '',
                payType: [],
                upFrontPayments: '',
            };
            for (var i = 0; i < parsedData[0].length; i++) {
                if (Array.isArray(dataRow[parsedData[0][i]])) {
                    if (parsedData[x][i].includes(','))
                    {
                        var parsedArrayData = parsedData[x][i].split(',')
                        for (var y = 0; y < parsedArrayData.length; y++) {
                            parsedArrayData[y] = parsedArrayData[y].trim();
                            dataRow[parsedData[0][i]].push({ value: parsedArrayData[y].toLowerCase(), label: parsedArrayData[y] });
                        }
                    }
                    else
                        dataRow[parsedData[0][i]].push({ value: parsedData[x][i].toLowerCase(), label: parsedData[x][i]});
                }
                else {
                    if (parsedData[0][i] === 'startDate') {
                        dataRow[parsedData[0][i]] = this.excelDateToJSDate(parsedData[x][i])
                    } else {
                        dataRow[parsedData[0][i]] = parsedData[x][i];
                    }
                }
            }
            tableData.push(dataRow);
        }

        return tableData;
    }

    excelDateToJSDate = (date) => {
        let converted_date = new Date(Math.round((date - 25569 + 1) * 86400 * 1000));
        const year = converted_date.getFullYear();
        const month = (converted_date.getMonth() + 1).toString().padStart(2, '0');
        const day = converted_date.getDate().toString().padStart(2, '0');
        return `${year}-${month}-${day}`;
    };


    handleDropDownChange = (selectedOption, columnIndex) => {
        var { tableData } = this.state
        var column = columnIndex.split('_')[0]
        var index = columnIndex.split('_')[1]
        tableData[index][column] = selectedOption
        this.setState({ tableData: tableData })
    };

    addRow = () => {
        var { tableData } = this.state
        tableData.push({
            firstName: '',
            lastName: '',
            email: '',
            selectedWorkLocations: [],
            selectedEmploymentType: '',
            isExempt: false,
            manager: [],
            jobTitle: '',
            startDate: '',
            selectedDirectReports: [],
            selectedDepartments: [],
            salary: '',
            payType: '',
            upFrontPayments: '',
        })

        this.setState({ tableData: tableData })
    }

    handleInputChange = (e) => {
        var { tableData } = this.state
        if (e.target.type === 'checkbox')
            tableData[e.target.name.split("_")[1]][e.target.name.split("_")[0]] = e.target.checked
        else
            tableData[e.target.name.split("_")[1]][e.target.name.split("_")[0]] = e.target.value
        this.setState({tableData: tableData})
    }

    importData = async () => {
        var { tableData } = this.state;

        for (var x = 0; x < tableData.length; x++) {
            if (tableData[x].firstName !== '' && tableData[x].lastName !== '' && tableData[x].email !== '') {
                var cleanHireObj = {
                    firstName: tableData[x].firstName,
                    lastName: tableData[x].lastName,
                    email: tableData[x].email,
                    selectedWorkLocations: tableData[x].selectedWorkLocations.map(x => { return x.label + " " }),
                    selectedEmploymentType: tableData[x].selectedEmploymentType.map(x => { return x.value })[0],
                    isExempt: tableData[x].isExempt.toString(),
                    manager: tableData[x].manager.map(x => { return x.label })[0],
                    jobTitle: tableData[x].jobTitle,
                    startDate: tableData[x].startDate,
                    selectedDirectReports: tableData[x].selectedDirectReports.map(x => { return x.label + " " }),
                    selectedDepartments: tableData[x].selectedDepartments.map(x => { return x.label + " " }),
                    salary: tableData[x].salary.toString(),
                    payType: tableData[x].payType.map(x => { return x.value })[0],
                    upFrontPayments: tableData[x].upFrontPayments.toString(),
                    entityGUID: [sessionStorage.getItem("x-params-2")].toString(),
                    hiringType: "Bulk"
                };

                cleanHireObj.selectedWorkLocations = cleanHireObj.selectedWorkLocations.join(",")
                cleanHireObj.selectedDirectReports = cleanHireObj.selectedDirectReports.join(",")
                cleanHireObj.selectedDepartments = cleanHireObj.selectedDepartments.join(",")
                var guid = await postApiData("HIRE_NEW_TEAM_MEMBER", [cleanHireObj])
                 //send email here with guid
            }
        }
    }


    render() {
        const { tableData } = this.state;
        return (
            <div className="container">
                <div className="row mt-3">
                    <div className="col-md-4">
                        <input type="file" className="form-control-file" onChange={this.handleFileUpload} />
                    </div>
                    <div className="col-md-4">
                        <a className="btn btn-primary" onClick={this.addRow}>Add new row</a>
                    </div>
                    <div className="col-md-4">
                        <a className="btn btn-warning" onClick={this.props.cancelHiring}>Cancel</a>
                    </div>
                </div>
                <br />
                <div style={{ height: "600px" }} className="table-responsive">
                   <table className="table table-bordered">
                            <thead className="thead-dark">
                                <tr>
                                    <th>First Name</th>
                                    <th>Last Name</th>
                                    <th>Email</th>
                                    <th>Work Location</th>
                                    <th>Employment Type</th>
                                    <th>Is Exempt?</th>
                                    <th>Manager</th>
                                    <th>Job Title</th>
                                    <th>Start Date</th>
                                    <th>Direct Reports</th>
                                    <th>Departments</th>
                                    <th>Salary</th>
                                    <th>Pay Type</th>
                                    <th>Upfront Payments</th>
                                </tr>
                            </thead>
                            <tbody>
                                {tableData.map((row, index) => (
                                    <tr key={index}>
                                        <td><input type="text" name={"firstName_" + index} onChange={this.handleInputChange} value={row.firstName}/></td>
                                        <td><input type="text" name={"lastName_" + index} onChange={this.handleInputChange} value={row.lastName}/></td>
                                        <td><input type="text" name={"email_" + index} onChange={this.handleInputChange} value={row.email}/></td>
                                        <td>
                                            <Select
                                                className="form-control bulk-edit-table-dropdown"
                                                classNamePrefix="select"
                                                isMulti
                                                value={row.selectedWorkLocations}
                                                options={this.state.workLocations}
                                                name={"selectedWorkLocations_" + index}
                                                onChange={(selectedOption) => this.handleDropDownChange(selectedOption, "selectedWorkLocations_"+index)}
                                            />
                                        </td>
                                        <td>
                                            <Select value={row["selectedEmploymentType"]} options={this.state.employmentTypes} name={"selectedEmploymentType_" + index} className="form-control bulk-edit-table-dropdown" onChange={(selectedOption) => this.handleDropDownChange(selectedOption, "selectedEmploymentType_" + index)}>
                                            </Select>
                                        </td>
                                        <td><input type="checkbox" name={"isExempt_" + index} onChange={this.handleInputChange} checked={row.isExempt} /></td>
                                        <td>
                                            <Select
                                                className="form-control bulk-edit-table-dropdown"
                                                classNamePrefix="select"
                                                value={row["manager"]}
                                                options={this.state.directReports}
                                                name={"manager_" + index}
                                                onChange={(selectedOption) => this.handleDropDownChange(selectedOption, "manager_" + index)}
                                            />
                                        </td>
                                        <td><input type="text" name={"jobTitle_" + index} onChange={this.handleInputChange} value={row.jobTitle} /> </td>
                                        <td><input type="date" name={"startDate_" + index} onChange={this.handleInputChange} value={row.startDate} /> </td>
                                        <td>
                                            <Select
                                                className="form-control bulk-edit-table-dropdown"
                                                classNamePrefix="select"
                                                isMulti
                                                value={row["selectedDirectReports"]}
                                                options={this.state.directReports}
                                                name={"selectedDirectReports_" + index}
                                                onChange={(selectedOption) => this.handleDropDownChange(selectedOption, "selectedDirectReports_" + index)}
                                            />
                                        </td>
                                        <td>
                                            <Select
                                                className="form-control bulk-edit-table-dropdown"
                                                classNamePrefix="select"
                                                isMulti
                                                value={row["selectedDepartments"]}
                                                options={this.state.departments}
                                                name={"selectedDepartments_" + index}
                                                onChange={(selectedOption) => this.handleDropDownChange(selectedOption, "selectedDepartments_" + index)}
                                            />
                                        </td>
                                        <td><input type="text" name={"salary_" + index} onChange={this.handleInputChange} value={row.salary}/></td>
                                        <td>
                                            <Select className="form-control  bulk-edit-table-dropdown" name={"payType_" + index} value={row["payType"]} onChange={(selectedOption) => this.handleDropDownChange(selectedOption, "payType_" + index)}
                                                options={this.state.payTypeOptions}>
                                            </Select>
                                        </td>
                                        <td><input type="checkbox" name={"upFrontPayments_" + index} onChange={this.handleInputChange} checked={row.upFrontPayments} /></td>
                                    </tr>
                                ))}
                            </tbody>
                   </table>
                </div>
                <div>
                    <p>
                        <b>WARNING</b>: Importing the above data will create an account with temporary credentials for each person being imported. It will then send each individual an email with their onboarding link. Please double check that all the information is correct before pressing import! 
                    </p>
                    <div>
                        <a onClick={this.importData} className="btn btn-primary">Import Data</a>
                    </div>
                </div>
           </div>
            );
    }
}

export default BulkHiring;