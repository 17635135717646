import React, { Component } from 'react';
import { postApiData } from '../../fetchApi';
import './Home.css'
import './nicepage.css'

export class Home extends Component {

    constructor(props) {
        super(props);
        this.containerRef = React.createRef()
        this.state = {
            htmlContent: ''
        };
    }

    componentDidMount = () => {
        fetch('/StaticHTMLContent/Home.html', { headers: { 'Content-Type': 'text/html; charset=utf-8' } })
            .then(response => response.text())
            .then(html => {
                this.setState({ htmlContent: html });
            });
    }

    componentDidUpdate = () => {
        const buttonEls = this.containerRef.current.querySelectorAll('.contact-sales');
        buttonEls.forEach(buttonEl => {
            buttonEl.addEventListener('click', this.handleContactSalesClick);
        });
        const formEl = this.containerRef.current.querySelector('form');
        formEl.addEventListener('submit', this.handleContactUsClick);
    }


    handleContactUsClick = (e) => {
        e.preventDefault();
        const formData = new FormData(e.target)
        var contactUsObj =
        {
            fullName: formData.get("name"),
            email: formData.get("email"),
            message: formData.get("message")
        }
        postApiData("CONTACT_US", [contactUsObj])
        this.setState({ isMessageSent: true })
    };

    handleContactSalesClick = (e) => {
        this.props.setNavigation("ContactUs")
    };

  render() {
      return (
          <div style={{ width: '60%', margin: '0 auto' }} dangerouslySetInnerHTML={{ __html: this.state.htmlContent }} ref={this.containerRef}></div>
    );
  }
}

export default Home;