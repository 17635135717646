import React, { Component } from 'react';
import { postApiData } from '../../../../fetchApi';

class ClientOnboardingStepOne extends Component {
    constructor(props) {
        super(props);

        this.state = {
            newUsername: '',
            newPassword: '',
            confirmPassword: '',
            formError: '',
            firstName: '',
            lastName: ''
        };
    }

    handleInputChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }

    handleSubmit = async (event) => {
        event.preventDefault();

        if (this.state.newPassword !== this.state.confirmPassword) {
            this.setState({
                formError: 'Passwords do not match.'
            });
            return;
        }
        var updatePasswordObj =
        {
            newPassword: this.state.newPassword,
            newUsername: this.state.newUsername,
            guid: this.props.guid,
            firstName: this.state.firstName,
            lastName: this.state.lastName
        }
        await postApiData("UPDATE_OWNER_ACCOUNT_INFO", [updatePasswordObj])
        this.props.trackOnboardingStep()
    }

    render() {
        return (
            <form onSubmit={this.handleSubmit}>
                <div>
                <label>
                    Enter Username:
                    <input className="form-control" name="newUsername" value={this.state.newUsername} onChange={this.handleInputChange} required type="text" />
                    </label>
                </div>
                <br />
                <label>
                    Enter New Password:
                    <input className="form-control" required type="password" name="newPassword" value={this.state.newPassword} onChange={this.handleInputChange} />
                </label>
                <br />
                <label>
                    Confirm New Password:
                    <input className="form-control" required type="password" name="confirmPassword" value={this.state.confirmPassword} onChange={this.handleInputChange} />
                </label>
                <br />
                <label>
                    Legal First Name:
                    <input className="form-control" required type="text" name="firstName" value={this.state.firstName} onChange={this.handleInputChange} />
                </label>
                <br />
                <label>
                    Legal Last Name:
                    <input className="form-control" required type="text" name="lastName" value={this.state.lastName} onChange={this.handleInputChange} />
                </label>
                {this.state.formError && <p className="error">{this.state.formError}</p>}
                <br />
                <button className="btn btn-primary" type="submit">Save</button>
            </form>
        );
    }
}

export default ClientOnboardingStepOne;
