import React, { Component } from 'react';
import { getApiData, postApiData } from '../../fetchApi';
import cloneDeep from 'lodash/cloneDeep';

export class PersonalInfo extends Component {

    constructor(props) {
        super(props)
        this.state = {
            apiData: null,
            editMode: false,
            editData: null
        }
    }

    componentDidMount = async () => {
        this.fetchData()
    }


    fetchData = async () => {
        const data = await getApiData("GET_TEAM_MEMBER_PERSONAL_DETAILS", [this.props.selectedTeamMemberId])
        this.setState({
            apiData: data
        })
    }

    setEditMode = (e) => {
        var data = this.state.apiData
        e.target.id === 'edit' ? this.setState({ editMode: true, editData: data }) : this.setState({editMode:false, editData: null})
    }

    save = async (e) => {
        var editData = this.state.editData
        await postApiData("SAVE_TEAM_MEMBER_PERSONAL_DETAILS", [editData[0]])
        this.setState({
            apiData: editData,
            editMode: false,
            editData: null
        })
    }

    updateData = (e) => {
        var data = this.state.editData === null ? cloneDeep(this.state.apiData) : cloneDeep(this.state.editData)
        data[0][e.target.id] = e.target.value
        this.setState({editData: data})
    }

    render() {
        return (
            this.state.apiData === null ? "" :
                <>
                    <h4>Personal Information </h4>
                    <div className="row team-member-info-container">
                        <div className="col-lg-6 team-member-info-header">
                            Legal First Name:
                        </div>
                        <div className="col-lg-6">
                            {
                                this.state.editMode ?
                                    <input type="text" onBlur={this.updateData} id="firstName" defaultValue={this.state.editData[0].firstName}></input> 
                                    :
                                    this.state.apiData[0].firstName
                            }
                        </div>
                    </div>
                    <div className="row team-member-info-container">
                        <div className="col-lg-6 team-member-info-header">
                            Legal Middle Name:
                        </div>
                        <div className="col-lg-6">
                            {
                                this.state.editMode ?
                                    <input type="text" onBlur={this.updateData} id="middleName" defaultValue={this.state.editData[0].middleName}></input>
                                    :
                                    this.state.apiData[0].middleName
                            }
                        </div>
                    </div>
                    <div className="row team-member-info-container">
                        <div className="col-lg-6 team-member-info-header">
                            Legal Last Name:
                        </div>
                        <div className="col-lg-6">
                            {
                                this.state.editMode ?
                                    <input type="text" onBlur={this.updateData} id="lastName" defaultValue={this.state.editData[0].lastName}></input>
                                    :
                                    this.state.apiData[0].lastName
                            }
                        </div>
                    </div>
                    <div className="row team-member-info-container">
                        <div className="col-lg-6 team-member-info-header">
                            Date of Birth:
                        </div>
                        <div className="col-lg-6">
                            {
                                this.state.editMode ?
                                    <input type="date" onBlur={this.updateData} id="dob" defaultValue={this.state.editData[0].dob}></input>
                                    :
                                    this.state.apiData[0].dob
                            }
                        </div>
                    </div>
                    <div className="row team-member-info-container">
                        <div className="col-lg-6 team-member-info-header">
                            Personal Email:
                        </div>
                        <div className="col-lg-6">
                            {
                                this.state.editMode ?
                                    <input type="email" onBlur={this.updateData} id="email" defaultValue={this.state.editData[0].email}></input>
                                    :
                                    this.state.apiData[0].email
                            }
                        </div>
                    </div>
                    <div className="row team-member-info-container">
                        <div className="col-lg-6 team-member-info-header">
                            Social Security Number:
                        </div>
                        <div className="col-lg-6">
                            {
                                this.state.editMode ?
                                    <input type="text" onBlur={this.updateData} id="ssn" defaultValue={this.state.editData[0].ssn}></input>
                                    :
                                    this.state.apiData[0].ssn
                            }
                        </div>
                    </div>
                    <div className="row team-member-info-container">
                        <div className="col-lg-6 team-member-info-header">
                            Country:
                        </div>
                        <div className="col-lg-6">
                            {
                                this.state.editMode ?
                                    <input type="text" onBlur={this.updateData} id="country" defaultValue={this.state.editData[0].country}></input>
                                    :
                                    this.state.apiData[0].country
                            }
                        </div>
                    </div>
                    <div className="row team-member-info-container">
                        <div className="col-lg-6 team-member-info-header">
                            Address Line One:
                        </div>
                        <div className="col-lg-6">
                            {
                                this.state.editMode ?
                                    <input type="text" onBlur={this.updateData} id="addressLineOne" defaultValue={this.state.editData[0].addressLineOne}></input>
                                    :
                                    this.state.apiData[0].addressLineOne
                            }
                        </div>
                    </div>
                    <div className="row team-member-info-container">
                        <div className="col-lg-6 team-member-info-header">
                            Address Line Two (Optional):
                        </div>
                        <div className="col-lg-6">
                            {
                                this.state.editMode ?
                                    <input type="text" onBlur={this.updateData} id="addressLineTwo" defaultValue={this.state.editData[0].addressLineTwo}></input>
                                    :
                                    this.state.apiData[0].addressLineTwo
                            }
                        </div>
                    </div>
                    <div className="row team-member-info-container">
                        <div className="col-lg-6 team-member-info-header">
                            City:
                        </div>
                        <div className="col-lg-6">
                            {
                                this.state.editMode ?
                                    <input type="text" onBlur={this.updateData} id="city" defaultValue={this.state.editData[0].city}></input>
                                    :
                                    this.state.apiData[0].city
                            }
                        </div>
                    </div>
                    <div className="row team-member-info-container">
                        <div className="col-lg-6 team-member-info-header">
                            Zip:
                        </div>
                        <div className="col-lg-6">
                            {
                                this.state.editMode ?
                                    <input type="text" onBlur={this.updateData} id="zip" defaultValue={this.state.editData[0].zip}></input>
                                    :
                                    this.state.apiData[0].zip
                            }
                        </div>
                    </div>
                    <div className="row team-member-info-container">
                        <div className="col-lg-6 team-member-info-header">
                            State:
                        </div>
                        <div className="col-lg-6">
                            {
                                this.state.editMode ?
                                    <input type="text" onBlur={this.updateData} id="state" defaultValue={this.state.editData[0].state}></input>
                                    :
                                    this.state.apiData[0].state
                            }
                        </div>
                    </div>
                    <div className="row team-member-info-container">
                        <div className="col-lg-6">
                            {
                                this.state.editMode ?
                                    <>
                                        <a id="save" style={{ marginRight: '10px' }} onClick={this.save} className="btn btn-primary">Save</a> 
                                        <a id="cancel" onClick={this.setEditMode} className="btn btn-warning">Cancel</a>
                                    </>
                                    :
                                    <a id="edit" onClick={this.setEditMode} className="btn btn-primary">Edit</a>
                            }
                        </div>
                    </div>
                </>
        );
    }
}

export default PersonalInfo
