import React, { Component } from 'react';
import EmploymentInfo from '../TeamMemberContent/EmploymentInfo';
import PersonalInfo from '../TeamMemberContent/PersonalInfo';
import CompensationInfo from '../TeamMemberContent/CompensationInfo';
import CustomFields from '../TeamMemberContent/CustomFields';

export class TeamMemberDetails extends Component {

    constructor(props) {
        super(props)
        this.state = {
            selectedDetail: 'employmentInfo',
            activeMenuItems: { 'employmentInfo': 'active', 'personalInfo': '', 'compensationInfo': '', 'directReports': '', 'customFields': '', 'documents': '', 'payrollInfo':'' }
        }
    }

    setActiveDetails = (e) => {
        var activeMenuItems = this.state.activeMenuItems;
        for (var key in activeMenuItems) {
            if (activeMenuItems.hasOwnProperty(key)) {
                if (key === e.target.id)
                    activeMenuItems[key] = 'active'
                else
                    activeMenuItems[key] = ''
            }
        }
        this.setState({ selectedDetail: e.target.id, activeMenuItems: activeMenuItems })
    }
    //add gender and emergency contact to personalinfo
    render() {
        return (
            <>
                <a className="btn btn-primary" onClick={this.props.backToTeamMembers}>Back To Team Members</a> <br /> <br />
                <div className="row">
                    <div className="col-lg-4">
                        <ul className='team-member-navi'>
                            <li id='employmentInfo' onClick={this.setActiveDetails} className={"team-member-details " + this.state.activeMenuItems['employmentInfo']}>Employement Information</li>
                            <li id='personalInfo' onClick={this.setActiveDetails} className={"team-member-details " + this.state.activeMenuItems['personalInfo']}>Personal Information</li>
                            <li id='compensationInfo' onClick={this.setActiveDetails} className={"team-member-details " + this.state.activeMenuItems['compensationInfo']}>Compensation Information</li>
                            <li id='directReports' onClick={this.setActiveDetails} className="team-member-details">Direct Reports</li>
                            <li id='customFields' onClick={this.setActiveDetails} className={"team-member-details " + this.state.activeMenuItems['customFields']}>Custom Fields</li>
                            <li id='documents' onClick={this.setActiveDetails} className="team-member-details">Documents</li>
                            <li id='payrollInfo' onClick={this.setActiveDetails} className="team-member-details">Payroll</li>
                        </ul>
                    </div>
                    <div className="col-lg-8">
                        {
                            {
                                'employmentInfo': <EmploymentInfo selectedTeamMemberId={this.props.selectedTeamMemberId} ></EmploymentInfo>,
                                'personalInfo': <PersonalInfo selectedTeamMemberId={this.props.selectedTeamMemberId}></PersonalInfo>,
                                'compensationInfo': <CompensationInfo selectedTeamMemberId={this.props.selectedTeamMemberId}></CompensationInfo>,
                               // 'directReports': <DirectReports selectedTeamMemberId={this.props.selectedTeamMemberId} ></DirectReports>,
                                'customFields': <CustomFields selectedTeamMemberId={this.props.selectedTeamMemberId}></CustomFields>,
                               // 'documents': <Documents selectedTeamMemberId={this.props.selectedTeamMemberId}></Documents>,
                               // 'payrollInfo': <PayrollInfo selectedTeamMemberId={this.props.selectedTeamMemberId}></PayrollInfo>
                            }[this.state.selectedDetail]
                        }
                   </div>
                </div>
            </>
       );
    }
}

export default TeamMemberDetails
