import React, { Component } from 'react';
import { Collapse, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import './NavMenu.css';
import { getApiData } from '../fetchApi';
import '../custom.css';

export class NavMenu extends Component {
  static displayName = NavMenu.name;

  constructor (props) {
    super(props);

    this.toggleNavbar = this.toggleNavbar.bind(this);
    this.state = {
        collapsed: true,
        entities: []
    };
    }

    componentDidMount = async () => {
        var apiData = await getApiData("GET_ENTITIES", [sessionStorage.getItem("x-params-1")])
        this.setState({ entities: apiData })
    }

  toggleNavbar () {
    this.setState({
      collapsed: !this.state.collapsed
    });
  }

    render() {
    var self = this
    return (
      <header>
        <Navbar className="navbar-expand-sm navbar-toggleable-sm ng-white border-bottom box-shadow mb-3" container light>
          <NavbarBrand tag={Link} to="/">simplifypayroll</NavbarBrand>
          <NavbarToggler onClick={this.toggleNavbar} className="mr-2" />
          <Collapse className="d-sm-inline-flex flex-sm-row-reverse" isOpen={!this.state.collapsed} navbar>
             <ul className="navbar-nav flex-grow">
              {
                this.state.entities.map(function (item, i) {
                var isActive = sessionStorage.getItem("x-params-2") === item.guid ? "active" : "";
                return <a href="/" key={i} className={"text-dark " + isActive} onClick={() => self.props.swapEntities(item.guid)}>{item.ein}</a>
              })}
            </ul>
          </Collapse>
        </Navbar>
      </header>
    );
  }
}
