import React, { Component } from 'react';
export default class PayrollCellRenderer extends Component {

    constructor(props) {

        super(props);

        this.state = {
            selectedBudgetOutput: null,
            selectedBudgetOutputId: null,
            accountId: null
        };

    }



    selectPayrollDetails = (params) => {
        this.props.getPayrollDetails(this.props.data.id)
    }



    render() {
        return (<a onClick={this.selectPayrollDetails} className="payroll-text">{new Array(this.props.value)}</a>)
    }

}
