import React, { Component } from 'react';

class PayrollOnboardingStartPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
           
        };
    }

    handleClick = (e) => {
        this.props.trackOnboardingStep(e.target.id, this.state)
    }

    render() {
        return (
            <>
                <div className="row">Please click Get Started to begin your payroll onboarding flow.</div>
                
                <div className='row'>
                    <div>
                        <button className="btn btn-primary" onClick={this.handleClick} id="continue" type="submit">Get Started</button>
                    </div>
                </div>
                      
                   
            </>
        );
    }
}

export default PayrollOnboardingStartPage;