import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import { getApiData, postApiData } from '../../../fetchApi';

export class ClientOnboarding extends Component {

    constructor(props) {
        super(props)
        this.state = {
            startDate: '',
            companyName: '',
            companyEmail: '',
            deadlineDate: '',
            freeMonths: '',
            tierOptions: [], // array to hold dropdown options for Tier column
            billingPlanOptions: [], // array to hold dropdown options for Billing Plan column
            implementationOptions: [],
            ownerId: null,
            isExisting: false,
            owners: []
        }
    }

   componentDidMount = async () => {
        // fetch data to populate tierOptions and billingPlanOptions arrays
        // ...

       // for example:
        var tierOptions = await getApiData("GET_SUBSCRIPTION_TIERS", [])
        var implementationOptions = await getApiData("GET_IMPLEMENTATION_OPTIONS", [])
        var owners = await getApiData("GET_OWNERS", [])
        var billingPlanOptions = []
        billingPlanOptions.push(tierOptions[0])

        this.setState({
            tierOptions: tierOptions,
            implementationOptions: implementationOptions,
            billingPlanOptions: billingPlanOptions,
            owners:owners
        });
    }

   handleInputChange = event => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

       if (value === false) {
           this.setState({
               [name]: value,
               ownerId: null
           });
       }
       else {
           this.setState({
               [name]: value
           });
       }
      
    }

    setBillingOptions = (e) => {
        var tierOptions = this.state.tierOptions
        var billingPlanOptions = []
        if (e.target.value === "Simplified") {
            billingPlanOptions.push(tierOptions[0])
            this.setState({ billingPlanOptions: billingPlanOptions })
        }

        else {
            billingPlanOptions.push(tierOptions[1])
            this.setState({ billingPlanOptions: billingPlanOptions })
        }
    }

    handleOwnerChange = (e) =>
    {
       const selectedOption = e.target.options[e.target.selectedIndex];
       const key = selectedOption.getAttribute("data-key");
       this.setState({ownerId:key})
    }

    handleSubmit = async event => {
        event.preventDefault();
        var formData = new FormData(event.target)

        var clientOnboardingObj =
        {
            companyName : formData.get("companyName"),
            companyEmail : formData.get("companyEmail"),
            contractStartDate : formData.get("startDate"),
            contractDeadlineDate : formData.get("deadlineDate"),
            contractFreeMonths : formData.get("freeMonths"),
            hrSeats : formData.get("HrSeats"),
            hrTier : formData.get("HrTier"),
            hrBillingPlan : formData.get("HrBillingPlan"),
            subscriptionStartDate : formData.get("SubscriptionStartDate"),
            hrDiscount: formData.get("HrDiscount"),
            hrDiscountPerSeat : formData.get("HrDiscountPerSeat"),
            prSeats : formData.get("PrSeats"),
            prBillingPlan : formData.get("PrBillingPlan"),
            prDiscountPerSeat : formData.get("PrDiscountPerSeat"),
            implementationSeat: formData.get("ImplementationSeat"),
            implementationBillingPlan: formData.get("ImplementationBillingPlan"),
            ownerId: this.state.ownerId,
            isExisting: this.state.isExisting.toString()
        }

        var guid = await postApiData("SAVE_CONTRACT", [clientOnboardingObj])

        var clientOnboardingEmailObj =
        {
            companyName: formData.get("companyName"),
            email: formData.get("companyEmail"),
            //TODO change this link to be dynamic later
            message: "Welcome to fluid payroll. Below is your personal link to get started with your onboarding. Do not share this link with anyone! <br /> https://localhost:44442/client-onboarding/" + guid.teamMemberGUID + "/" + guid.entityGUID,
            guid: guid.teamMemberGUID,
            entityGuid: guid.entityGUID,
        }

        await postApiData("SEND_CONTRACT", [clientOnboardingEmailObj])

    }

    render() {
        return (
            <>
                <div>
                    <input type="checkbox" name="isExisting" checked={this.state.isExisting} onClick={this.handleInputChange} />   Does this client already exist in the system?
                </div> <br />
                {
                    this.state.isExisting ? 
                        <>
                        <div>
                        <b>Select an owner to onboard to another entity</b>
                                <select name="owners" onChange={this.handleOwnerChange} className="form-control">
                                <option key="">Choose an owner</option>
                                {this.state.owners.map(option => (
                                    <option data-key={option.id}>{option.legalFirstName} {option.legalLastName}</option>
                                ))}
                            </select>
                        </div> <br /> </>
                    : ""
                    
                }
                <form onSubmit={this.handleSubmit}>
                    <div className="row">
                        <div className="col-lg-12">
                            <label htmlFor="startDate"><b>Company Name:</b></label>
                            <input className="form-control" type="text" id="companyName" name="companyName" required value={this.state.companyName} onChange={this.handleInputChange} />
                        </div>
                    </div> <br />
                    <div className="row">
                        <div className="col-lg-12">
                            <label htmlFor="startDate"><b>Company Primary Email:</b></label>
                            <input className="form-control" type="text" id="companyEmail" name="companyEmail" required value={this.state.companyEmail} onChange={this.handleInputChange} />
                        </div>
                    </div> <br />
                    <div className="row">
                        <div className="col-lg-12">
                            <label htmlFor="startDate"><b>Contract Start Date:</b></label>
                            <input className="form-control" type="date" id="startDate" name="startDate" required value={this.state.startDate} onChange={this.handleInputChange} />
                        </div>
                    </div> <br />
                    <div className="row">
                        <div className="col-lg-12">
                            <label htmlFor="deadlineDate"><b>Contract Signature Deadline Date:</b></label>
                            <input className="form-control" type="date" id="deadlineDate" name="deadlineDate" value={this.state.deadlineDate} onChange={this.handleInputChange} />
                        </div>
                    </div><br />
                    <div className="row">
                        <div className="col-lg-12">
                            <label htmlFor="freeMonths"><b>Number of free months:</b></label>
                            <input className="form-control" type="number" id="freeMonths" name="freeMonths" value={this.state.freeMonths} onChange={this.handleInputChange} />
                        </div>
                    </div><br />
                    <div className="table-responsive">
                        <table className="table table-bordered">
                            <thead>
                                <tr>
                                    <th>Subscription</th>
                                    <th style={{ width:"100px" }}>Seats</th>
                                    <th style={{ width: "125px" }}>Tier</th>
                                    <th>Billing Plan</th>
                                    <th>Subscription Start Date</th>
                                    <th style={{ width: "50px" }}>Discount %</th>
                                    <th style={{ width: "50px" }}>Discount per Seat %</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Fluid Payroll HR</td>
                                    <td><input name="HrSeats" type="number" className="form-control" /></td>
                                        <td>
                                            <select name="HrTier" onChange={this.setBillingOptions} className="form-control">
                                            {this.state.tierOptions.map(option => (
                                                <option key={option.id}>{option.tierName}</option>
                                            ))}
                                        </select>
                                    </td>
                                    <td>
                                            <select name="HrBillingPlan" className="form-control">
                                              {this.state.billingPlanOptions.map(option => (
                                                  <option key={option.id}>{option.tierPlan} ${option.tierPrice} + ${option.tierPricePerSeat} per seat</option>
                                            ))}
                                        </select>
                                    </td>
                                    <td><input name="SubscriptionStartDate" type="date" className="form-control" /></td>
                                    <td><input name="HrDiscount" type="number" className="form-control" /></td>
                                    <td><input name="HrDiscountPerSeat" type="number" className="form-control" /></td>
                                </tr>
                                <tr>
                                    <td>Fluid Payroll Services</td>
                                    <td><input name="PrSeats" type="number" className="form-control" /></td>
                                    <td>
                                        <select className="form-control" disabled>
                                            {/* pre-populate with selected Tier from previous row */}
                                        </select>
                                    </td>
                                    <td>
                                        <select name="PrBillingPlan" className="form-control">
                                            {this.state.billingPlanOptions.map(option => (
                                                <option key={option.id}>${option.tierPricePerSeat} per seat</option>
                                            ))}
                                        </select>
                                    </td>
                                    <td><input disabled readOnly type="text" className="form-control" /></td>
                                    <td><input disabled readOnly type="number" className="form-control" /></td>
                                    <td><input name="PrDiscountPerSeat" type="number" className="form-control" /></td>
                                </tr>
                                <tr>
                                    <td>Implementation</td>
                                    <td><input name="ImplementationSeat" type="number" className="form-control" readOnly value="1" /></td>
                                    <td><select className="form-control" disabled></select></td>
                                    <td>
                                        <select name="ImplementationBillingPlan" className="form-control">
                                            {this.state.implementationOptions.map(option => (
                                                <option key={option.id}>{option.implementationName} ${option.implementationPrice}</option>
                                            ))}
                                        </select>
                                    </td>
                                    <td><input type="text" className="form-control" disabled /></td>
                                    <td><input type="number" className="form-control" disabled /></td>
                                    <td><input type="number" className="form-control" disabled /></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                <br />
                <div className="row">
                    <div className="col-lg-12">
                        <button className="btn btn-primary" type="submit">Save</button>
                    </div>
                </div>
                    </form>

        </>
        );
    }
}

export default ClientOnboarding
