import config from './Config/config.json';



export async function getApiData(apiType, params) {

    try {

        var url = config[apiType];

        var replaceOccurences = (url.match(/<<REPLACEME>>/g) || []).length;
        for (var i = 0; i < replaceOccurences; i++) {

            url = url.replace("<<REPLACEME>>", params[i]);

        }

        const settings =
        {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                //Authorization: "Token " + localStorage.getItem("AuthToken")
            }
         }
        var apiData = await fetch(url, settings)
        apiData = await apiData.json()
        return apiData;

    }

    catch (e) {

        return e;

    }



}



export async function getAuthToken(apiType, params) {

    var url = localStorage.getItem("API_BASE_URL") + config[apiType];

    const settings =

    {

        method: 'POST',

        headers: {

            'Accept': 'application/json',

            'Content-Type': 'application/json'

        },

        body: JSON.stringify({

            username: params[0],

            password: params[1]

        })





    }

    var token = await fetch(url, settings)

        .then((res) => res.json())

        .then((json) => {

            return json;

        })



    return token;

}

export async function postApiData(apiType, params) {

    var url = config[apiType];

    const settings =

    {
        method: 'POST',

        headers: {

            'Accept': 'application/json',

            'Content-Type': 'application/json'

        },

        body: JSON.stringify(params[0])
    }

    var apiData = await fetch(url, settings)
        .then((res) => res.json())
        .then((json) => {
            return json;
        })
    return apiData;
}


export async function postApiFormData(apiType, formData) {

    var url = config[apiType];

    const settings =

    {
        method: 'POST',
        body: formData,
    }

    var apiData = await fetch(url, settings)
        .then((res) => res.json())
        .then((json) => {
            return json;
        })
    return apiData;
}


export default getApiData