import React, { Component } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-material.css';
import PayrollCellRenderer from '.././components/CellRenderers/PayrollCellRenderer';
import TeamMemberCellRenderer from '.././components/CellRenderers/TeamMemberCellRenderer';
import ProcessPayrollRenderer from '.././components/CellRenderers/ProcessPayrollRenderer';
import EntityCellRenderer from '.././components/CellRenderers/EntityCellRenderer';
import { getApiData, postApiData } from '.././fetchApi';

export class MainGridComponent extends Component {

    constructor(props) {

        super(props);

        window.addEventListener('resize', this.resizeColumns)

        this.state = {
            show: false,
            errorMsg: "",
            accountId: "",
            apiData: null,
            selectedBudgetOutput: null,
            selectedBudgetOutputId: null,
            gridApi: null,
            gridColumnApi: null,
            payrollCellRenderer: PayrollCellRenderer,
            processPayrollRenderer: ProcessPayrollRenderer,
            teamMemberCellRenderer: TeamMemberCellRenderer,
            entityCellRenderer: EntityCellRenderer,
        };

    }

    componentDidMount = async () => {
        switch (this.props.pagetype) {
            case "Payroll":
                await this.fetchDataPayroll()
                break;
            case "PayrollDetails":
                await this.fetchDataPayrollDetails()
                break;
            case "TeamMembers":
                await this.fetchDataTeamMembers()
                break;
            case "Admin":
                await this.fetchDataEntities()
                break;
            case "Subscriptions":
                await this.fetchSubscriptionData()
                break;
            case "Discounts":
                await this.fetchDiscountData()
                break;
            case "Payment Methods":
                await this.fetchPaymentData()
                break;
            case "Refunds & Credits":
                await this.fetchRefundData()
                break;
            case "Invoices":
                await this.fetchInvoiceData()
                break;
            case "Failed Invoices":
                await this.fetchFailedInvoiceData()
                break;
            case "Billing Transactions":
                await this.fetchBillingData()
                break;
            case "Contracts":
                await this.fetchContractData()
                break;
            case "Exemptions & Blockers":
                await this.fetchExemptionData()
                break;
        }
    }

    componentDidUpdate = async (prevProps, prevState) => {
        if (this.props.pagetype === 'Admin' && this.props.userRoleId !== prevProps.userRoleId)
            await this.fetchDataEntities()
    }

    fetchDataPayroll = async () => {
        const response = await getApiData("GET_PAYROLL_LIST", [sessionStorage.getItem("x-params-2")]);
        this.setState({
            apiData: response
        })
    }

    fetchDataPayroll = async () => {
        const response = await getApiData("GET_PAYROLL_DATA", [this.props.payrollId]);
        this.setState({
            apiData: response
        })
    }

    createNewPayroll = async () => {
        await postApiData("CREATE_PAYROLL", [sessionStorage.getItem("x-params-2")]);
        const response = await getApiData("GET_PAYROLL_LIST", [sessionStorage.getItem("x-params-2")]);
        this.setState({
            apiData: response
        })
    }

    fetchDataTeamMembers = async () => {
        const response = await getApiData("GET_TEAM_MEMBER_LIST", [sessionStorage.getItem("x-params-2")]);
        this.setState({
            apiData: response
        })
    }

    fetchDataEntities = async () => {
        if (this.props.userRoleId === 1) {
            const response = await getApiData("GET_ENTITY_LIST", [this.props.userRoleId]);
            this.setState({
                apiData: response
            })
        }
    }

    fetchSubscriptionData = async () => {
        const response = await getApiData("GET_SUBSCRIPTIONS_BY_ENTITY", [this.props.selectedEntityId]);
        this.setState({
            apiData: response
        })
    }

    fetchPaymentMethodsData = async () => {
        const response = await getApiData("GET_PAYMENT_METHODS_BY_ENTITY", [this.props.selectedEntityId]);
        this.setState({
            apiData: response
        })
    }

    fetchRefundsCreditsData = async () => {
        const response = await getApiData("GET_REFUNDS_CREDITS_BY_ENTITY", [this.props.selectedEntityId]);
        this.setState({
            apiData: response
        })
    }

    fetchInvoicesData = async () => {
        const response = await getApiData("GET_INVOICES_BY_ENTITY", [this.props.selectedEntityId]);
        this.setState({
            apiData: response
        })
    }

    fetchFailedInvoicesData = async () => {
        const response = await getApiData("GET_FAILED_INVOICES_BY_ENTITY", [this.props.selectedEntityId]);
        this.setState({
            apiData: response
        })
    }

    fetchBillingTransactionsData = async () => {
        const response = await getApiData("GET_BILLING_TRANSACTIONS_BY_ENTITY", [this.props.selectedEntityId]);
        this.setState({
            apiData: response
        })
    }

    fetchContractsData = async () => {
        const response = await getApiData("GET_CONTRACTS_BY_ENTITY", [this.props.selectedEntityId]);
        this.setState({
            apiData: response
        })
    }

    fetchExemptionsData = async () => {
        const response = await getApiData("GET_EXEMPTIONS_BY_ENTITY", [this.props.selectedEntityId]);
        this.setState({
            apiData: response
        })
    }

    onGridReady = (params) => {

        this.setState({

            gridApi: params.api,

            gridColumnApi: params.columnApi

        })

    };


    getPayrollDetails = (payrollId) =>
    {
        this.props.setSelectedPayroll(payrollId);
    }

    getTeamMemberDetails = (teamMemberId) => {
        this.props.setSelectedTeamMember(teamMemberId);
    }

    getEntityDetails = (entityId) => {
        this.props.setSelectedEntity(entityId);
    }

    toTitles = (s) => { return s.replace(/\w\S*/g, function (t) { return t.charAt(0).toUpperCase() + t.substr(1).toLowerCase(); }); }


    getColumnDefs = () => {

        var columnDefs = [];

        var colDef = null;

        if (this.state.apiData != null && this.state.apiData.length > 0) {

            var columnApiData = Object.keys(this.state.apiData[0]);

            for (var i = 0; i < columnApiData.length; i++)
            {

                colDef = null;
                colDef =

                {

                    headerName: this.toTitles(columnApiData[i]),

                    field: columnApiData[i],

                    editable: true,
                };

                switch (columnApiData[i]) {

                    case 'payrollName':
                        colDef.cellRenderer = this.state.payrollCellRenderer
                        colDef.cellRendererParams = {
                            getPayrollDetails: this.getPayrollDetails,
                        }
                        break
                    case 'fullName':
                        colDef.cellRenderer = this.state.teamMemberCellRenderer
                        colDef.cellRendererParams = {
                            getTeamMemberDetails: this.getTeamMemberDetails,
                        }
                        break
                    case 'ein':
                        colDef.cellRenderer = this.state.entityCellRenderer
                        colDef.cellRendererParams = {
                            getEntityDetails: this.getEntityDetails,
                        }
                        break
                    case 'id':
                        colDef.hide = true
                        break
                    case 'clientId':
                        colDef.hide = true
                        break
                    case 'payrollDetails':
                        colDef.hide = true
                        break
                    default:
                        break
                }

                if (colDef !== null) {
                    columnDefs.push(colDef)
                }

            }
            if (this.props.pagetype === 'Payroll') {
                colDef = null;
                colDef =
                {
                    headerName: "Action",
                    editable: false,
                    cellRenderer: this.state.processPayrollRenderer
                };
                columnDefs.push(colDef)
            }
           
        }
        return columnDefs;
    }

    render() {

        return (

            <>
                {this.props.pagetype === "Payroll" ? <btn onClick={this.createNewPayroll} className="btn btn-primary">CREATE ON CYLCE PAYROLL - TESTING ONLY</btn> : ""}
                <div

                id="myGrid"

                style={{

                    height: '600px',

                    width: '100%',

                }}

                className="ag-theme-material"

            >
                   <AgGridReact
                    rowData={this.state.apiData} 
                    onGridReady={this.onGridReady}
                    onFirstDataRendered={this.onFirstDataRendered}
                    columnDefs={this.getColumnDefs()}
                    isFullWidthRow={this.props.pagetype === "TeamMembers" ? this.state.isFullWidthCell : ""}
                    getRowHeight={this.state.getRowHeight}
                    pagination={this.props.pagetype === "BudgetResultsOutput" ? false : true}
                    paginationPageSize={20} 
                    rowSelection={'single'}
                    editType='fullRow' /> 

            </div>
            </>

        );

    }

}

export default MainGridComponent