import React, { Component } from 'react';
import HiringStepOne from '../TeamMemberContent/HiringSteps/HiringStepOne'
import HiringStepTwo from '../TeamMemberContent/HiringSteps/HiringStepTwo'
import HiringStepThree from '../TeamMemberContent/HiringSteps/HiringStepThree'
import HiringStepFour from '../TeamMemberContent/HiringSteps/HiringStepFour'
import HiringComplete from '../TeamMemberContent/HiringSteps/HiringComplete'
import { postApiData } from '../../fetchApi';
import PayrollOnboardingStepOne from './PayrollOnboardingSteps/PayrollOnboardingStepOne'
import PayrollOnboardingStepTwo from './PayrollOnboardingSteps/PayrollOnboardingStepTwo'
import PayrollOnboardingStepThree from './PayrollOnboardingSteps/PayrollOnboardingStepThree'
import PayrollOnboardingStepFour from './PayrollOnboardingSteps/PayrollOnboardingStepFour'
import PayrollOnboardingStepFive from './PayrollOnboardingSteps/PayrollOnboardingStepFive'
import PayrollOnboardingStepSix from './PayrollOnboardingSteps/PayrollOnboardingStepSix'
import PayrollOnboardingStartPage from './PayrollOnboardingSteps/PayrollOnboardingStartPage'
import PayrollOnboardingComplete from './PayrollOnboardingSteps/PayrollOnboardingComplete'

class PayrollOnboarding extends Component {

    constructor(props) {
        super(props)
        this.state = {
            onboardStep: 0,
            payrollOnboardingObj: {
                hasRunPayroll: null,
                previousProvider: '',
                username: '',
                password: '',
                additionalInfo: '',
                sellsPharmaceuticals: null,
                partOfCannabisIndustries: null,
                earnsRevenueFromGaming: null,
                ownsStripClubsOrPornography: null,
                businessActivity: '',
                selectedAccount: null,
                selectedPayScheduleType: '',
                contractorPayScheduleType: '',
                selectedPayFrequency: '',
                contractorPayFrequency: '',
                isAutoApprove: false,
                arrears: true,
                beforeOrAfterHoliday: 'before',
                semiMonthlyFirstCheckDay: '',
                semiMonthlySecondCheckDay: '',
                contractorSemiMonthlyFirstCheckDay: '',
                contractorSemiMonthlySecondCheckDay: '',
                ein: '',
                selectedTeamMemberId: 0,
                teamMembers: [],
                federalFrequency: '',
                federalForm: '',
                federalFilingAddress: '',
                states: [],
                selectedState: '',
                stateWithholding: [],
                stateWithholdingData: {},
                stateSUI: [],
                btnValue: "Use owner as signatory",
                dropDownValue: '',
                selectedOption: '',
                selectedDeduction: '',
                defaultEmployeeContribution: '',
                defaultEmployerContribution: '',
                annualLimits: {
                    individualFilers: '',
                    familyFilers: '',
                    separateFilers: '',
                    employerContribution: '',
                    employerContributionWithDependants: '',
                },
                deductionsData: [],
                deductionsSelected: []
            }
        }
    }

    cancelOnboarding = () => {
        var onboardStep = 0;
        var payrollOnboardingObj =
        {
            hasRunPayroll: null,
            previousProvider: '',
            username : '',
            password : '',
            additionalInfo : '',
            sellsPharmaceuticals : null,
            partOfCannabisIndustries : null,
            earnsRevenueFromGaming : null,
            ownsStripClubsOrPornography : null,
            businessActivity: '',
            selectedAccount: null,
            selectedPayScheduleType: '',
            contractorPayScheduleType: '',
            selectedPayFrequency: '',
            contractorPayFrequency: '',
            isAutoApprove: false,
            arrears: true,
            beforeOrAfterHoliday: 'before',
            semiMonthlyFirstCheckDay: '',
            semiMonthlySecondCheckDay: '',
            contractorSemiMonthlyFirstCheckDay: '',
            contractorSemiMonthlySecondCheckDay: '',
            ein: '',
            selectedTeamMemberId: 0,
            teamMembers: [],
            federalFrequency: '',
            federalForm: '',
            federalFilingAddress: '',
            states: [],
            selectedState: '',
            stateWithholding: [],
            stateWithholdingData: {},
            stateSUI: [],
            btnValue: "Use owner as signatory",
            dropDownValue: '',
            selectedOption: '',
            selectedDeduction: '',
            defaultEmployeeContribution: '',
            defaultEmployerContribution: '',
            annualLimits: {
                individualFilers: '',
                familyFilers: '',
                separateFilers: '',
                employerContribution: '',
                employerContributionWithDependants: '',
            },
            deductionsData: [],
            deductionsSelected: []
        }
        this.setState({
            onboardStep: onboardStep,
            payrollOnboardingObj: payrollOnboardingObj
            })
    }

    trackOnboardingStep = (id, data) => {
        var guid = ""
        var onboardStep = this.state.onboardStep
        if (id === "continue") {
            onboardStep = onboardStep + 1
        }
        else if (id === "back") {
            onboardStep = onboardStep - 1
        }

        var payrollOnboardingObj = this.state.payrollOnboardingObj
        var keys = Object.keys(data)

        for (var x = 0; x < keys.length; x++) {
            payrollOnboardingObj[keys[x]] = data[keys[x]]
        }

        if (id === "save") {
            var cleanPayrollOnboardObj = {

                hasRunPayroll: null,
                previousProvider: '',
                username: '',
                password: '',
                additionalInfo: '',
                sellsPharmaceuticals: null,
                partOfCannabisIndustries: null,
                earnsRevenueFromGaming: null,
                ownsStripClubsOrPornography: null,
                businessActivity: '',
                selectedAccount: null,
                selectedPayScheduleType: '',
                contractorPayScheduleType: '',
                selectedPayFrequency: '',
                contractorPayFrequency: '',
                isAutoApprove: false,
                arrears: true,
                beforeOrAfterHoliday: 'before',
                selectedTeamMemberId: 0,
                federalFrequency: '',
                federalForm: '',
                federalFilingAddress: '',
                selectedState: '',
                stateWithholdingData: {},
                deductionsSelected: {},
                entityGuid: sessionStorage.getItem('x-params-2')
            };

            Object.keys(cleanPayrollOnboardObj).forEach(field => {
                if (payrollOnboardingObj.hasOwnProperty(field)) {
                    cleanPayrollOnboardObj[field] = payrollOnboardingObj[field];
                }
            });

            if (payrollOnboardingObj["selectedPayScheduleType"] === '2') {
                cleanPayrollOnboardObj["selectedPayFrequency"] = payrollOnboardingObj["semiMonthlyFirstCheckDay"] + " and " + payrollOnboardingObj["semiMonthlySecondCheckDay"]
            }

            if (payrollOnboardingObj["contractorPayScheduleType"] === '2') {
                cleanPayrollOnboardObj["contractorPayFrequency"] = payrollOnboardingObj["contractorSemiMonthlyFirstCheckDay"] + " and " + payrollOnboardingObj["contractorSemiMonthlyFirstCheckDay"]
            }

            cleanPayrollOnboardObj["stateWithholdingData"] = JSON.stringify(cleanPayrollOnboardObj["stateWithholdingData"])
            cleanPayrollOnboardObj["deductionsSelected"] = JSON.stringify(cleanPayrollOnboardObj["deductionsSelected"])
            cleanPayrollOnboardObj["isAutoApprove"] = JSON.stringify(cleanPayrollOnboardObj["isAutoApprove"])
            cleanPayrollOnboardObj["selectedTeamMemberId"] = JSON.stringify(cleanPayrollOnboardObj["selectedTeamMemberId"])
            cleanPayrollOnboardObj["arrears"] = JSON.stringify(cleanPayrollOnboardObj["arrears"])
            postApiData("ONBOARD_NEW_PAYROLL", [cleanPayrollOnboardObj])
            onboardStep = onboardStep + 1;
        }

        this.setState({ onboardStep: onboardStep, payrollOnboardingObj: payrollOnboardingObj, guid: guid })
    }

    render() {
        return (
            <div className="col-lg-8">
                {
                    {
                        0: <PayrollOnboardingStartPage trackOnboardingStep={this.trackOnboardingStep}></PayrollOnboardingStartPage>,
                        1: <PayrollOnboardingStepOne payrollOnboardingObj={this.state.payrollOnboardingObj} cancelOnboarding={this.cancelOnboarding} trackOnboardingStep={this.trackOnboardingStep}></PayrollOnboardingStepOne>,
                        2: <PayrollOnboardingStepTwo payrollOnboardingObj={this.state.payrollOnboardingObj} cancelOnboarding={this.cancelOnboarding} trackOnboardingStep={this.trackOnboardingStep}></PayrollOnboardingStepTwo>,
                        3: <PayrollOnboardingStepThree payrollOnboardingObj={this.state.payrollOnboardingObj} cancelOnboarding={this.cancelOnboarding} trackOnboardingStep={this.trackOnboardingStep}></PayrollOnboardingStepThree>,
                        4: <PayrollOnboardingStepFour payrollOnboardingObj={this.state.payrollOnboardingObj} cancelOnboarding={this.cancelOnboarding} trackOnboardingStep={this.trackOnboardingStep}></PayrollOnboardingStepFour>,
                        5: <PayrollOnboardingStepFive payrollOnboardingObj={this.state.payrollOnboardingObj} cancelOnboarding={this.cancelOnboarding} trackOnboardingStep={this.trackOnboardingStep}></PayrollOnboardingStepFive>,
                        6: <PayrollOnboardingStepSix payrollOnboardingObj={this.state.payrollOnboardingObj} cancelOnboarding={this.cancelOnboarding} trackOnboardingStep={this.trackOnboardingStep}></PayrollOnboardingStepSix>,
                        7: <PayrollOnboardingComplete payrollOnboardingObj={this.state.payrollOnboardingObj} cancelOnboarding={this.cancelOnboarding} trackOnboardingStep={this.trackOnboardingStep}></PayrollOnboardingComplete>
                    }[this.state.onboardStep]
                }
            </div>
        );
    }
}

export default PayrollOnboarding;