import React, { Component } from 'react';
export default class TeamMemberCellRenderer extends Component {

    constructor(props) {

        super(props);

        this.state = {
            
        };

    }



    selectTeamMemberDetails = (params) => {
        this.props.getTeamMemberDetails(this.props.data.id)
    }



    render() {
        return (<a onClick={this.selectTeamMemberDetails} className="payroll-text">{new Array(this.props.value)}</a>)
    }

}
