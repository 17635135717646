import React, { Component } from 'react';
import { getApiData } from '../../../fetchApi';
import Select from 'react-select'

class HiringStepTwo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            workLocations: [],
            selectedWorkLocations: [],
            selectedEmploymentType: '',
            isExempt: false,
        };
    }

    componentDidMount = async () => {
        var officeData = await getApiData("GET_OFFICES", [sessionStorage.getItem('x-params-2')])
        var officeOptions = []
        officeOptions = officeData.map(x => { return { value: x.id, label: x.officeName + ", " + x.officeAddressOne } })

        var keys = Object.keys(this.props.newHireObj)
        var newHireInfo = this.state
        for (var x = 0; x < keys.length; x++) {
            if (newHireInfo[keys[x]] !== undefined) {
                newHireInfo[keys[x]] = this.props.newHireObj[keys[x]]
            }
        }
        this.setState({ workLocations: officeOptions });
    }

    handleWorkLocationChange = (selectedOptions) => {
        this.setState({ selectedWorkLocations: selectedOptions });
    };

    handleEmploymentTypeChange = event => {
        this.setState({ selectedEmploymentType: event.target.value });
    };

    handleExemptChange = event => {
        this.setState({ isExempt: event.target.value === 'Exempt' });
    };

    trackHiringStep = e => {
        var hiringInfo = this.state
        this.props.trackHiringStep(e.target.id, hiringInfo)
    }

    render() {
        return (
            <div>
                <div className="row">
                    <div className="col-lg-12"><b>Office Location:</b> <br />
                        <Select
                            isMulti
                            name="offices"
                            options={this.state.workLocations}
                            className="basic-multi-select"
                            classNamePrefix="select"
                            onChange={this.handleWorkLocationChange}
                            value={this.state.selectedWorkLocations}
                        />
                    </div>
                </div>
                <br />
                <div className="row">
                    <div className="col-lg-12"><b>Employment Type:</b> <br />
                        <select value={this.state.selectedEmploymentType} className="form-control" onChange={this.handleEmploymentTypeChange}>
                            <option value="Contractor">Contractor</option>
                            <option value="Salaried full-time">Salaried, full-time</option>
                            <option value="Salaried part-time">Salaried, part-time</option>
                            <option value="Hourly full-time">Hourly, full-time</option>
                            <option value="Hourly part-time">Hourly, part-time</option>
                            <option value="Temporary / Intern">Temporary / Intern</option>
                        </select>
                    </div>
                </div>
                <br />
                <div className="row">
                    <div className="col-lg-12">
                        <b>Is the employee exempt?</b> <br />
                        <input
                            type="radio"
                            value="Exempt"
                            checked={this.state.isExempt}
                            onChange={this.handleExemptChange}
                        />
                        Exempt - not eligible for overtime pay
                    </div>
                <br />
                <div className="row">
                    <div className="col-lg-12">
                    <input
                            type="radio"
                            value="Non-Exempt"
                            checked={!this.state.isExempt}
                            onChange={this.handleExemptChange}
                        />
                        Non-Exempt - eligible for overtime pay
                    </div>
                </div>
                    <br />
                    <br />
                <div className="row">
                    <div className="col-lg-2"><button id="continue" onClick={this.trackHiringStep} className="btn btn-primary">Continue</button></div>
                     <div className="col-lg-2"><button id="back" className="btn btn-warning" onClick={this.trackHiringStep}>Back</button></div>
                    <div className="col-lg-2"><button onClick={this.props.cancelHiring} className="btn btn-danger">Cancel</button></div>
                </div>
                </div>
            </div>
        );
    }
}

export default HiringStepTwo