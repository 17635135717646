import React, { Component } from 'react';
import { getApiData, postApiData } from '../../fetchApi';
import cloneDeep from 'lodash/cloneDeep';

export class CustomFields extends Component {

    constructor(props) {
        super(props)
        this.state = {
            apiData: null,
            editMode: false,
            editData: null,
            customFieldHeader: "",
            customFieldValue: "",
            addMode: false

        }
    }

    componentDidMount = async () => {
        this.fetchData()
    }


    fetchData = async () => {
        const data = await getApiData("GET_CUSTOM_FIELDS", [sessionStorage.getItem("x-params-2")])
        this.setState({
            apiData: data,
            addMode: false,
            editMode: false,
        })
    }

    setEditMode = (e) => {
        var data = cloneDeep(this.state.apiData)
        e.target.id === 'edit' ? this.setState({ editMode: true, editData: data }) : this.setState({ editMode: false, editData: null })
    }

    save = async (e) => {
        var editData = cloneDeep(this.state.editData)
        await postApiData("UPDATE_CUSTOM_FIELD", [editData])
        this.setState({
            apiData: editData,
            editMode: false,
            editData: null,
        })
    }

    create = async (e) => {
        var customFieldInfo = { customFieldHeader: this.state.customFieldHeader, customFieldValue: this.state.customFieldValue, guid: sessionStorage.getItem('x-params-2') }
        await postApiData("CREATE_CUSTOM_FIELD", [customFieldInfo])
        this.fetchData();
    }

    delete = async (e) => {
        var apiData = cloneDeep(this.state.apiData)
        var customFieldId = e.target.id.split('_')[1]
        var dataToDelete = apiData.filter(x => x.id.toString() === customFieldId)
        apiData = apiData.filter(x => x.id.toString() !== customFieldId)
        await postApiData("DELETE_CUSTOM_FIELD", dataToDelete)
        this.setState({
            apiData: apiData,
        })
    }

    updateData = (e) => {
        var data = this.state.editData === null ? cloneDeep(this.state.apiData) : cloneDeep(this.state.editData)
        data = data.map(x => {
            if (x.id.toString() === e.target.id.split('_')[1])
            {
                x.customFieldValue = e.target.value
            }
            return x;
        })
        this.setState({ editData: data })
    }

    setCustomField = (e) => {
        if (e.target.id === "CustomFieldHeader")
            this.setState({ customFieldHeader: e.target.value })
        else
            this.setState({ customFieldValue: e.target.value })
    }

    toggleAddMode = (e) => {
        if (e.target.id === "addnew")
            this.setState({ addMode: true })
        else
            this.setState({ addMode: false })
    }

    render() {
        return (
            this.state.apiData === null ? "" :
                <>
                    <h4>Custom Fields </h4> <br />
                    {this.state.editMode ? "" : <><div><a className="btn btn-primary" id="addnew" onClick={this.toggleAddMode}>Add New Custom Field</a></div> <br /></>}
                    <div className="row team-member-info-container">
                        <div className="row">
                            <div className="col-lg-5 team-member-info-header">
                                <span>Custom Field Header</span>
                            </div>
                            <div className="col-lg-5 team-member-info-header">
                                <span>Custom Field Value</span>
                            </div>
                        </div>
                        {
                           this.state.apiData.map(customField => (
                           <><div className="row">
                               <div className="col-lg-5">
                                {
                                   <div style={{ display: 'inline-block', padding: '10px' }}> {customField.customFieldHeader} </div>
                                }
                               </div>
                               <div className="col-lg-5">
                                    <div className="row" style={{ padding: '10px' }} >
                                    {
                                        this.state.editMode ?
                                            <div><input type="text" onBlur={this.updateData} id={"customfieldvalue_" + customField.id} defaultValue={customField.customFieldValue}></input></div>
                                            :
                                            <div> {customField.customFieldValue}</div>
                                    }
                                    </div>
                               </div>
                               <div className="col-lg-2">
                                       {
                                           this.state.editMode ? "" : <a id={"delete_" + customField.id} onClick={this.delete} className="btn btn-danger">Delete</a>
                                       }
                               </div>
                           </div></>
                        ))}
                    </div>
                    {
                        this.state.addMode ?
                            <><div className="row team-member-info-container">
                                <div className="col-lg-6">
                                    <input type="text" onBlur={this.setCustomField} id="CustomFieldHeader"></input>
                                </div>
                                <div className="col-lg-6">
                                    <input type="text" onBlur={this.setCustomField} id="CustomFieldValue"></input>
                                </div>
                            </div>
                            <div className="row team-member-info-container">
                                <div className="col-lg-6">
                                    <a id="create" style={{ marginRight: '10px' }} onClick={this.create} className="btn btn-primary">Save</a>
                                    <a id="cancelCreate" onClick={this.toggleAddMode} className="btn btn-warning">Cancel</a>
                                </div>
                            </div></>
                            :
                            <div className="row team-member-info-container">
                                <div className="col-lg-6">
                                    {
                                        this.state.editMode ?
                                            <>
                                                <a id="save" style={{ marginRight: '10px' }} onClick={this.save} className="btn btn-primary">Save</a>
                                                <a id="cancel" onClick={this.setEditMode} className="btn btn-warning">Cancel</a>
                                            </>
                                            :
                                            <a id="edit" onClick={this.setEditMode} className="btn btn-primary">Edit</a>
                                    }
                                </div>
                            </div> 
                    }
                  
                </>
        );
    }
}

export default CustomFields
