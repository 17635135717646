import React, { Component } from 'react';
export default class ProcessPayrollRenderer extends Component {

    constructor(props) {

        super(props);

        this.state = {
     
        };

    }





    render() {
        return (<a className="btn btn-primary">Run Payroll</a>)
    }

}
