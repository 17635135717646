import React, { Component } from 'react';


class ManualOnboarding extends Component {

    constructor(props) {
        super(props)
        this.state = {

        }
    }

    render() {
        return (
           <div>test</div>
            );
    }
}

export default ManualOnboarding;