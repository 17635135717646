import React, { Component } from 'react';
import '../../custom.css';
import Cookies from 'universal-cookie';
import { postApiData } from '../../fetchApi';
import Navigation from './Nav';
import ContactUs from './ContactUs';
import Login from './Login'
import Pricing from './Pricing'
import Home from './Home'

var cookies = new Cookies();
export class NavigationMain extends Component {
  
  constructor (props) {
    super(props);
    this.state = {
      NavigationSelection: "ContactUs"
    };
  }

  setNavigation = (navItem) =>
  {
    this.setState({NavigationSelection: navItem})
  }

  render () {
    return (
        <>
        <Navigation setNavigation={this.setNavigation}></Navigation>
        {
          {
          "Login": <Login setupSession={this.props.setupSession}></Login>,
          "ContactUs": <ContactUs setNavigation={this.setNavigation}></ContactUs>,
          "Pricing": <Pricing setNavigation={this.setNavigation}></Pricing>,
          "Home": <Home setNavigation={this.setNavigation}></Home>
          }[this.state.NavigationSelection]
        }
        </>
      );

  }
}
export default NavigationMain