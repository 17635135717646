import React, { Component } from 'react';
import '../../custom.css';
import Cookies from 'universal-cookie';
import { postApiData } from '../../fetchApi';

var cookies = new Cookies();
export class Login extends Component {
  
  constructor (props) {
    super(props);
    this.state = {
      username: '',
      password: '',
      isRememberMeChecked: false,
      isForgotPasswordSelected: false,
      isEmailValid: true,
      isLoginWrong: false,
      html: ''
    };
  }

  componentDidMount = () =>
  {
    if (cookies.get('remember') !== undefined)
    {
        //redirect to home
    }


  }

  displayForgotPasswordForm = () =>
  {
      this.setState({isForgotPasswordSelected: true})
  }

  formSubmit = async () =>
  {
      var userInfo = { username: this.state.username, password: this.state.password }
      var userData = await postApiData("LOGIN", [userInfo])
      if (userData) {
          if (this.state.isRememberMeChecked) {
              cookies.set('remember', userData.GUID, { path: '/', expires: new Date(Date.now() + 2592000 * 30) });
          }
          this.props.setupSession(userData)
      }
      else
      {
         this.setState({isLoginWrong : true})
      }
  }

  setUsername = (e) =>
  {
    // if(e.target.value.length > 0)
    // {
    //     let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    //     if ( re.test(e.target.value) ) {
    //         this.setState({username: e.target.value, isEmailValid:true})
    //     }
    //     else {
    //         this.setState({isEmailValid: false})
    //     }
    // } 

    this.setState({username: e.target.value, isEmailValid:true})
  }

  setPassword = (e) =>
  {
    this.setState({password: e.target.value})
  }

  setRememberMe = (e) =>
  {
      this.setState({isRememberMeChecked: e.target.checked})
  }

  submitPasswordReset = () =>
  {

  }

  backToLogin = () =>
  {
    this.setState({isForgotPasswordSelected: false, isLearnMoreSelected: false})
  }

  setNavigation = () =>
  {

  }

  render () {
    let { isEmailValid, isLoginWrong, isForgotPasswordSelected } = this.state;
    return (
        <div className='center'>
            <div className='row'>
               
                {isForgotPasswordSelected ? 
                <div className='col-md-6 login-form'>
                    <div className='padding'><input onBlur={this.setUsername} className='form-textbox' type="email" placeholder='Email' name="email"></input> </div>
                    <div className='validation-error' style={{display: isEmailValid ? 'none' : 'block'}}>Please enter a valid email</div>
                    <div className='padding'><button onClick={this.submitPasswordReset} className='form-button'>Reset Password</button></div> 
                    <div className='padding'><button onClick={this.backToLogin} className='form-button'>Cancel</button></div>
                </div>
                :
                <div className='col-md-6 login-form'>
                    <div className="form-headers">Email address</div>
                    <div className='padding'><input onBlur={this.setUsername} className='form-textbox' type="email" placeholder='Email' name="email"></input> </div>
                    <div className='validation-error' style={{display: isEmailValid ? 'none' : 'block'}}>Please enter a valid email</div>
                    <div className="form-headers">Password</div>
                    <div className='padding'><input onChange={this.setPassword} className='form-textbox' type="password" placeholder='Password' name="password"></input></div>
                    <div className='padding'><button onClick={this.formSubmit} className='form-button'>Sign In</button></div> 
                    <div className='validation-error' style={{display: isLoginWrong ? 'block' : 'none' }}>Email or password is incorrect</div>
                    <div className='padding form-div-width center-text'><label className='form-checkbox'><input onChange={this.setRememberMe} type="checkbox" /> Remember Me</label></div>
                    <div className='padding form-div-width center-text'><a className='forgot-password' href="#" onClick={this.displayForgotPasswordForm}>Forgot Password?</a></div>
                    <div className='padding form-div-width center-text'><label className='form-checkbox'>Don't have an account?</label> <a href="#">Contact Us</a></div>
                </div> 
                }
            </div>
       </div>
    );
  }
}
export default Login