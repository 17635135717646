import React, { Component } from 'react';
import { postApiData } from '../../../../fetchApi';
import SignatureCanvas from '../SignatureCanvas'

class OnboardingStepFour extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isExempt: false,
            showConfirmation: false,
            selectedFilingStatus: '',
            singleOrMarriedFilingSeparately: false,
            claimedDeductions: 0,
            claimedDependants: 0,
            additionalIncome: 0,
            isMultipleJobs: ""

        };
    }

    handleExemptChange = (event) => {
        this.setState({
            isExempt: event.target.checked,
            showConfirmation: event.target.checked ? true : false
        });
    }


    singleOrMarriedFilingSeparatelyChange = (event) => {
        this.setState({
            singleOrMarriedFilingSeparately: event.target.checked ? true : false
        });
    }

    handleFilingStatusChange = (event) => {
        this.setState({
            selectedFilingStatus: event.target.value
        });
    }

    handleClaimedDependants = (event) => {
        this.setState({
            claimedDependants: event.target.value
        });
    }

    handleAdditionalIncome = (event) => {
        this.setState({
            additionalIncome: event.target.value
        });
    }

    handleClaimedDeductions = (event) => {
        this.setState({
            claimedDeductions: event.target.value
        });
    }

    handleConfirmation = () => {
        // handle confirmation logic here
    }

    handleFederalWithholding = () => {


        
        var federalWithholdingObj =
        {
            filingType: this.state.selectedFilingStatus,
            claimedDeductions: this.state.claimedDeductions,
            claimedDependants: this.state.claimedDependants,
            additionalIncome: this.state.additionalIncome,
            guid: this.props.guid,
            isMultipleJobs: this.state.isMultipleJobs,
            entityGuid:  this.props.entityGuid
        }
        postApiData("SAVE_FEDERAL_WITHHOLDING", [federalWithholdingObj])
    }

    setMultipleJobs = (e) => {
        this.setState({isMultipleJobs: e.target.value})
    }

    renderConfirmationSection() {
        return (
            <div>
                <p>Are you sure you are exempt from federal withholding?</p>
                <p>Most people are not exempt from federal tax withholding.</p>
                <button onClick={this.handleConfirmation}>Yes, I am sure</button>
            </div>
        );
    }

    renderFilingStatusSection() {
        return (
            <div>
                <p>Please select your filing status:</p>
                <label>
                    <input
                        type="radio"
                        name="filingStatus"
                        value="single"
                        checked={this.state.selectedFilingStatus === 'single'}
                        onChange={this.handleFilingStatusChange}
                    />
                    Single
                </label>
                <label>
                    <input
                        type="radio"
                        name="filingStatus"
                        value="marriedFilingSeparately"
                        checked={this.state.selectedFilingStatus === 'marriedFilingSeparately'}
                        onChange={this.handleFilingStatusChange}
                    />
                    Married Filing Separately
                </label>
                <label>
                    <input
                        type="radio"
                        name="filingStatus"
                        value="filingMarriedJointly"
                        checked={this.state.selectedFilingStatus === 'filingMarriedJointly'}
                        onChange={this.handleFilingStatusChange}
                    />
                    Filing Married Jointly
                </label>
                <label>
                    <input
                        type="radio"
                        name="filingStatus"
                        value="headOfHousehold"
                        checked={this.state.selectedFilingStatus === 'headOfHousehold'}
                        onChange={this.handleFilingStatusChange}
                    />
                    Head of Household
                </label>
            </div>
        );
    }

    render() {
        return (
            <>
            <div>
                <label>
                    <input
                        type="checkbox"
                        checked={this.state.isExempt}
                        onChange={this.handleExemptChange}
                    />
                    Yes, I am exempt from federal withholding
                </label>
                <label>
                    <input
                        type="checkbox"
                        checked={!this.state.isExempt}
                        onChange={this.handleExemptChange}
                    />
                    No, I am not exempt from federal withholding
                </label>
                {this.state.showConfirmation ? this.renderConfirmationSection() : null}
                {this.state.isExempt ? null : this.renderFilingStatusSection()}
            </div>

            <div>
                {
                        {
                        //TODO Revisit this, prolly dont need the switch statement
                            "single": 
                        
                            <div>
                                <label>
                                    <input
                                        type="checkbox"
                                        checked={this.state.singleOrMarriedFilingSeparately}
                                        value="Yes"
                                        onChange={this.singleOrMarriedFilingSeparatelyChange}
                                    />
                                    Yes, I am exempt from federal withholding
                                </label>
                                <label>
                                    <input
                                        type="checkbox"
                                        value="No"
                                        checked={!this.state.singleOrMarriedFilingSeparately}
                                        onChange={this.singleOrMarriedFilingSeparatelyChange}
                                    />
                                    No, I am not exempt from federal withholding
                                </label>
                                <div>
                                    <input
                                        type="checkbox"
                                        checked={this.state.isMultipleJobs}
                                        value="Yes"
                                        onChange={this.setMultipleJobs}
                                    />
                                    Yes
                                    <input
                                        type="checkbox"
                                        checked={!this.state.isMultipleJobs}
                                        value="No"
                                        onChange={this.setMultipleJobs}
                                    />
                                    No
                                </div>
                                <div>Will you claim deductions other than the standard deductions to reduce your withholding? (Line 4b)</div>
                                <div><input onChange={this.handleClaimedDeductions} value={this.state.claimedDeductions}></input></div><br />
                                <div>Do you have any other income to declare? (Line 4a)</div>
                                <div><input onChange={this.handleAdditionalIncome} value={this.state.additionalIncome}></input></div><br />
                                <div>Would you like to claim any dependants? ($2000 per dependant under 17, $500 per any other dependants (Line 3)</div>
                                <div><input onChange={this.handleClaimedDependants} value={this.state.claimedDependants}></input></div><br />
                                <div><button onClick={this.handleFederalWithholding} className="btn btn-primary">Save</button></div>
                                </div>,

                            "marriedFilingSeparately":

                                <div>
                                    <label>
                                        <input
                                            type="checkbox"
                                            checked={this.state.singleOrMarriedFilingSeparately}
                                            value="Yes"
                                            onChange={this.singleOrMarriedFilingSeparatelyChange}
                                        />
                                        Yes, I am exempt from federal withholding
                                    </label>
                                    <label>
                                        <input
                                            type="checkbox"
                                            value="No"
                                            checked={!this.state.singleOrMarriedFilingSeparately}
                                            onChange={this.singleOrMarriedFilingSeparatelyChange}
                                        />
                                        No, I am not exempt from federal withholding
                                    </label>
                                    <div>
                                        <input
                                            type="checkbox"
                                            checked={this.state.isMultipleJobs}
                                            value="Yes"
                                            onChange={this.setMultipleJobs}
                                        />
                                        Yes
                                        <input
                                            type="checkbox"
                                            checked={!this.state.isMultipleJobs}
                                            value="No"
                                            onChange={this.setMultipleJobs}
                                        />
                                        No
                                    </div>
                                    <div>Will you claim deductions other than the standard deductions to reduce your withholding? (Line 4b)</div>
                                    <div><input onChange={this.handleClaimedDeductions} value={this.state.claimedDeductions}></input></div><br />
                                    <div>Do you have any other income to declare? (Line 4a)</div>
                                    <div><input onChange={this.handleAdditionalIncome} value={this.state.additionalIncome}></input></div><br />
                                    <div>Would you like to claim any dependants? ($2000 per dependant under 17, $500 per any other dependants (Line 3)</div>
                                    <div><input onChange={this.handleClaimedDependants} value={this.state.claimedDependants}></input></div><br />
                                    <div><button onClick={this.handleFederalWithholding} className="btn btn-primary">Save</button></div>
                                </div>,


                        "filingMarriedJointly":"",
                        "headOfHousehold": ""
                    }[this.state.selectedFilingStatus]
                }
                </div>
                <br />
                <SignatureCanvas entityGuid={this.props.entityGuid} signatureType="_w4Signature" guid={this.props.guid} ></SignatureCanvas>
            </>
        );
    }
}

export default OnboardingStepFour