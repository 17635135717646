import React, { Component } from 'react';
import MainGridComponent from '../MainGridComponent'
import { getApiData } from '../../fetchApi';
import AdminEntityDetails from './AdminEntityDetails'
import ClientOnboarding from './ClientOnboarding/ClientOnboarding'


export class Admin extends Component {

    constructor(props) {
        super(props)
        this.state = {
            isClientOnboarding: false,
            selectedEntityId: 0,
            userRoleId: 0
        }
    }

    componentDidMount = async () => {
        var apiData = await getApiData("GET_USER_BY_GUID", [sessionStorage.getItem('x-params-1'), false])
        if (apiData[0].userRoleId !== 1) {
            window.location.replace('/')
        }
        else
            this.setState({ userRoleId: apiData[0].userRoleId })
    }

    //nachaTesting = async () => {
      //  var apiData = await getApiData("NACHA_TESTING", [])
   // }

    cancelOnboarding = () => {
        this.setState({ isClientOnboarding:false })
    }

    setOnboarding = () => {
        this.setState({ isClientOnboarding: true })
    }

    backToAdminScreen = () => {
        this.setState({ selectedEntityId: 0 })
    }

    setSelectedEntity = (entityId) => {
        this.setState({ selectedEntityId: entityId })
    }


    render() {
        return (
            this.state.isClientOnboarding ? <ClientOnboarding cancelOnboarding={this.cancelHiring} ></ClientOnboarding>
                :
                this.state.selectedEntityId > 0 ?
                    <AdminEntityDetails backToAdminScreen={this.backToAdminScreen} selectedEntityId={this.state.selectedEntityId} ></AdminEntityDetails>
                    :
                    <>
                        <div><button onClick={this.setOnboarding} className="btn btn-primary">New Client Onboarding</button></div> <br />
                        <MainGridComponent userRoleId={this.state.userRoleId} setSelectedEntity={this.setSelectedEntity} pagetype="Admin"></MainGridComponent>
                    </>
        );
    }
}
