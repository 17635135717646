import React, { Component } from 'react';
import './Pricing.css'
import './nicepage.css'

export class Pricing extends Component {

    constructor(props) {
        super(props);
        this.containerRef = React.createRef()
        this.state = {
            htmlContent: ''
        };
    }

    componentDidMount = () => {
        fetch('/StaticHTMLContent/Pricing.html', { headers: { 'Content-Type': 'text/html; charset=utf-8' } })
            .then(response => response.text())
            .then(html => {
                this.setState({ htmlContent: html });
            });
    }

    componentDidUpdate = () => {
        const buttonEls = this.containerRef.current.querySelectorAll('.contact-sales');
        buttonEls.forEach(buttonEl => {
            buttonEl.addEventListener('click', this.handleContactSalesClick);
        });
    }

    handleContactSalesClick = (e) => {
        this.props.setNavigation("ContactUs")
    };


  render() {
      return (
            <div style={{ width: '60%', margin: '0 auto' }} dangerouslySetInnerHTML={{ __html: this.state.htmlContent }} ref={this.containerRef}></div> 
    );
  }
}

export default Pricing;