import React, { Component } from 'react';
import { getApiData } from '../../fetchApi';


export class ToDoContent extends Component {

    constructor(props) {
    super(props);
    this.state = {toDoTaskData: [] };
  }

    componentDidMount = async () => {
        this.fetchData()
    }

    fetchData = async () => {
        const response = await getApiData("GET_TO_DO_LIST", [sessionStorage.getItem("x-params-2")]);
        this.setState({ toDoTaskData: response});
    }

  render() {
      return (
      <>
          <div className="welcome-user-text">Welcome Xergs </div>
          <div className="welcome-text">Here's what's going on with your team at Skatina LLC </div>
          <div className="things-to-do-header">Things to do</div>
          {this.state.toDoTaskData.map(toDoTask => (
            <>
                <div className="to-do-task-container">
                    <div  className="to-do-task-item-header">
                        {toDoTask.taskHeader}
                    </div>
                     <div className="to-do-task-item-body">
                        {toDoTask.taskBody}
                    </div>
                     <div className="to-do-cta">
                        Let's do it
                    </div>
                </div> <br />
            </>
          ))}
          
      </>
    );
  }
}

export default ToDoContent
