import React, { Component } from 'react';
import MainGridComponent from '../MainGridComponent';

class AdminEntityDetails extends Component {
    constructor(props) {
        super(props);

        this.state = {
            activeMenuItem: 'Subscriptions',
            selectedEntityId: this.props.selectedEntityId
        };
    }

    handleMenuItemClick = (menuItem) => {
        this.setState({ activeMenuItem: menuItem });
    };

    render() {
        const { activeMenuItem } = this.state;

        return (
            <>
                <a onClick={this.props.backToAdminScreen} className="btn btn-primary">Back To Admin</a>
                <div className="container">
                <ul className="nav">
                    <li className="nav-item">
                        <a
                            className={`nav-link ${activeMenuItem === 'Subscriptions' ? 'active' : ''}`}
                            onClick={() => this.handleMenuItemClick('Subscriptions')}
                        >
                            Subscriptions
                        </a>
                    </li>
                    <li className="nav-item">
                        <a
                            className={`nav-link ${activeMenuItem === 'Discounts' ? 'active' : ''}`}
                            onClick={() => this.handleMenuItemClick('Discounts')}
                        >
                            Discounts
                        </a>
                    </li>
                    <li className="nav-item">
                        <a
                            className={`nav-link ${activeMenuItem === 'Payment Methods' ? 'active' : ''}`}
                            onClick={() => this.handleMenuItemClick('Payment Methods')}
                        >
                            Payment Methods
                        </a>
                    </li>
                    <li className="nav-item">
                        <a
                            className={`nav-link ${activeMenuItem === 'Refunds & Credits' ? 'active' : ''}`}
                            onClick={() => this.handleMenuItemClick('Refunds & Credits')}
                        >
                            Refunds & Credits
                        </a>
                    </li>
                    <li className="nav-item">
                        <a
                            className={`nav-link ${activeMenuItem === 'Invoices' ? 'active' : ''}`}
                            onClick={() => this.handleMenuItemClick('Invoices')}
                        >
                            Invoices
                        </a>
                    </li>
                    <li className="nav-item">
                        <a
                            className={`nav-link ${activeMenuItem === 'Failed Invoices' ? 'active' : ''}`}
                            onClick={() => this.handleMenuItemClick('Failed Invoices')}
                        >
                            Failed Invoices
                        </a>
                    </li>
                    <li className="nav-item">
                        <a
                            className={`nav-link ${activeMenuItem === 'Billing Transactions' ? 'active' : ''}`}
                            onClick={() => this.handleMenuItemClick('Billing Transactions')}
                        >
                            Billing Transactions
                        </a>
                    </li>
                    <li className="nav-item">
                        <a
                            className={`nav-link ${activeMenuItem === 'Contracts' ? 'active' : ''}`}
                            onClick={() => this.handleMenuItemClick('Contracts')}
                        >
                            Contracts
                        </a>
                    </li>
                    <li className="nav-item">
                        <a
                            className={`nav-link ${activeMenuItem === 'Exemptions & Blockers' ? 'active' : ''}`}
                            onClick={() => this.handleMenuItemClick('Exemptions & Blockers')}
                        >
                            Exemptions & Blockers
                        </a>
                    </li>
                </ul>
            </div>

            <MainGridComponent pagetype={this.state.activeMenuItem} selectedEntityId={this.state.selectedEntityId}></MainGridComponent>

            </>
        );
    }
}

export default AdminEntityDetails;
