import React, { Component } from 'react';

class HiringStepOne extends Component {
    constructor(props) {
        super(props)
        this.state = {
            hireOption: '',
            candidateOption: '',
            firstName: '',
            lastName: '',
            email: '',
            isHire: false,
            isBackGroundCheck: false,
            isTrackingSystem: false,
            isPreviousEmployee: false,
            isManual: false
        }
    }
    

    componentDidMount = () => {
        var keys = Object.keys(this.props.newHireObj)
        var newHireInfo = this.state
        for (var x = 0; x < keys.length; x++) {
            if (newHireInfo[keys[x]] !== undefined) {
                newHireInfo[keys[x]] = this.props.newHireObj[keys[x]]
            }
        }
        this.setState({})
    }

    handleHireOptionChange = event => {
        var isHire = false
        var isBackGroundCheck = false
        if (event.target.value === 'hire')
            isHire = true
        else
            isBackGroundCheck = true
        this.setState({ hireOption: event.target.value, isHire: isHire, isBackGroundCheck: isBackGroundCheck, isTrackingSystem: false, isPreviousEmployee: false, isManual: false });
    }

    handleCandidateOptionChange = event => {
        var isTrackingSystem = false
        var isPreviousEmployee = false
        var isManual = false

        if (event.target.value === 'ats')
            isTrackingSystem = true
        else if (event.target.value === 'rehire')
            isPreviousEmployee = true
        else
            isManual = true

        this.setState({ candidateOption: event.target.value, isTrackingSystem: isTrackingSystem, isManual: isManual, isPreviousEmployee: isPreviousEmployee });
    }

    handleFirstNameChange = event => {
        this.setState({ firstName: event.target.value });
    }

    handleLastNameChange = event => {
        this.setState({ lastName: event.target.value });
    }

    handleEmailChange = event => {
        this.setState({ email: event.target.value });
    }

    trackHiringStep = e => {
        var hiringInfo = this.state
        this.props.trackHiringStep(e.target.id, hiringInfo)
    }

    render() {
        return (
            <div>
                <div className="row">
                    <div className="col-lg-12 radio-btn-container">
                        <input type="radio" name="hireOption" checked={this.state.isHire} className="form-check-input" value="hire" onChange={this.handleHireOptionChange} /> Hire Someone
                    </div>
                    
                </div>
                <div className="row">
                    <div className="col-lg-12 radio-btn-container">
                        <input type="radio" name="hireOption" checked={this.state.isBackGroundCheck} className="form-check-input" value="backgroundCheck" onChange={this.handleHireOptionChange} /> Run background Check
                    </div>
                </div>
                {this.state.hireOption === 'hire' && (
                    <div>
                        <div className="row">
                            <div className="col-lg-12 radio-btn-container">
                                <input type="radio" name="candidateOption" checked={this.state.isTrackingSystem} value="ats" onChange={this.handleCandidateOptionChange} /> I'll be selecting a candidate from my applicant tracking system
                            </div>

                        </div>
                        <div className="row">
                            <div className="col-lg-12 radio-btn-container">
                                <input type="radio" name="candidateOption" checked={this.state.isPreviousEmployee} value="rehire" onChange={this.handleCandidateOptionChange} /> I'll be rehiring a previous employee
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-12 radio-btn-container">
                                <input type="radio" name="candidateOption" checked={this.state.isManual} value="manual" onChange={this.handleCandidateOptionChange} /> I'll be entering the candidate details manually
                            </div>
                        </div>
                        {this.state.candidateOption === 'manual' && (
                            <div className="row">
                                <div className="col-lg-12 radio-btn-container"> 
                                    <label><b>First Name: </b></label> <br />
                                    <input type="text" className="form-control" name="firstName" defaultValue={this.state.firstName} onChange={this.handleFirstNameChange} />
                                </div>
                                <div className="col-lg-12 radio-btn-container">
                                    <label><b>Last Name: </b></label> <br />
                                    <input type="text" className="form-control" name="lastName" defaultValue={this.state.lastName} onChange={this.handleLastNameChange} />
                                </div>
                                <div className="col-lg-12 radio-btn-container">
                                    <label><b>Email:</b> </label> <br />
                                    <input type="text" className="form-control" name="email" defaultValue={this.state.email} onChange={this.handleEmailChange} />
                                </div>
                            </div>
                        )}
                    </div>
                )}
                <div className="row">
                    <div className="col-lg-2"><button id="continue" onClick={this.trackHiringStep} className="btn btn-primary">Continue</button></div>
                    <div className="col-lg-2"><button onClick={this.props.cancelHiring}  className="btn btn-warning">Cancel</button></div>
                </div>
                
            </div>
        );
    }
}

export default HiringStepOne;