import React, { Component } from 'react';
import Calendar from 'react-calendar'
import 'react-calendar/dist/Calendar.css';

export class CalendarContent extends Component {

    constructor(props) {
        super(props);
        this.state = {value: ""};
    }

    componentDidMount() {
    
    }

    onChange = (e) => {
        this.setState({ value: e })
    }



    render() {
        return (
            <div>
                <Calendar onChange={this.onChange} value={this.state.value} />
            </div>
        )
    }
}

export default CalendarContent
