import React, { Component } from 'react';
import { postApiData } from '../../../../fetchApi';

class OnboardingStepOne extends Component {
    constructor(props) {
        super(props);

        this.state = {
            username: this.props.username,
            newPassword: '',
            confirmPassword: '',
            formError: ''
        };
    }

    handleInputChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }

    handleSubmit = async (event) => {
        event.preventDefault();

        if (this.state.newPassword !== this.state.confirmPassword) {
            this.setState({
                formError: 'Passwords do not match.'
            });
            return;
        }
        var updatePasswordObj =
        {
            newPassword: this.state.newPassword,
            guid: this.props.guid
        }
        await postApiData("UPDATE_TEAMMEMBER_PASSWORD", [updatePasswordObj])
        this.props.trackOnboardingStep()
    }

    render() {
        return (
            <form onSubmit={this.handleSubmit}>
                <div>
                <label>
                    Your Username:
                    <input className="form-control" type="text" value={this.props.username} readOnly />
                    </label>
                </div>
                <br />
                <label>
                    Enter New Password:
                    <input className="form-control" type="password" name="newPassword" value={this.state.newPassword} onChange={this.handleInputChange} />
                </label>
                <br />
                <label>
                    Confirm New Password:
                    <input className="form-control" type="password" name="confirmPassword" value={this.state.confirmPassword} onChange={this.handleInputChange} />
                </label>
                {this.state.formError && <p className="error">{this.state.formError}</p>}
                <br />
                <button className="btn btn-primary" type="submit">Save</button>
            </form>
        );
    }
}

export default OnboardingStepOne;
