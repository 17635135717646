import React from 'react';
import { postApiData, postApiFormData } from '../../../../fetchApi';

class ClientOnboardingStepThree extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            entityType: '',
            legalName: '',
            dbaName: '',
            sicCode: '',
            phoneNumber: '',
            emailDomain: '',
            soleProprietorName: '',
            taxedAsSCorp: false,
            irsTreatsLLCAsCorp: '',
            ein: '',
            ownerGuid: this.props.guid,
            entityAddress: '',
            contractId: this.props.contractId
        };
    }

    handleEntityTypeChange = (event) => {
        this.setState({ entityType: event.target.value });
    }

    handleInputChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }

    handleSubmit = async (event) => {
        event.preventDefault();
        var formData = this.state;
        formData["taxedAsSCorp"] = formData["taxedAsSCorp"].toString()
        formData["entityGuid"] = this.props.entityGuid
        var apiData = await postApiData("SAVE_ENTITY_DATA", [formData])
        this.props.trackOnboardingStep(null, apiData.id)
        
    }

    render() {
        return (
            <form>
                <div className="form-group">
                    <label htmlFor="entityType">Entity Type</label>
                    <select className="form-control" id="entityType" name="entityType" value={this.state.entityType} onChange={this.handleEntityTypeChange}>
                        <option value="">Select an entity type</option>
                        <option value="C-corp">C-corp</option>
                        <option value="S-corp">S-corp</option>
                        <option value="Sole Proprietor">Sole Proprietor</option>
                        <option value="LLC">LLC</option>
                        <option value="LLP">LLP</option>
                        <option value="limited Partnership">Limited Partnership</option>
                        <option value="Trusteeship">Trusteeship</option>
                        <option value="General Partnership">General Partnership</option>
                        <option value="Non Profit">Non Profit</option>
                    </select>
                </div>

                {this.state.entityType === 'C-corp' && (
                    <div className="form-group">
                        <p>Not common, but please select if your company is taxed as an S-Corp:</p>
                        <div className="form-check">
                            <input className="form-check-input" type="radio" name="taxedAsSCorp" id="taxedAsCCorp" checked={!this.state.taxedAsSCorp} onChange={() => this.setState({ taxedAsSCorp: false })} />
                            <label className="form-check-label" htmlFor="taxedAsCCorp">
                                The company is taxed as a C-corp
                            </label>
                        </div>
                        <div className="form-check">
                            <input className="form-check-input" type="radio" name="taxedAsSCorp" id="taxedAsSCorp" checked={this.state.taxedAsSCorp} onChange={() => this.setState({ taxedAsSCorp: true })} />
                            <label className="form-check-label" htmlFor="taxedAsSCorp">
                                The company is taxed as an S-Corp
                            </label>
                        </div>
                    </div>
                )}

                {this.state.entityType === 'LLC' && (
                    <div className="form-group">
                        <p>Does the IRS treat your LLC as a corporation?</p>
                        <div className="form-check">
                            <input className="form-check-input" type="radio" name="irsTreatsLLCAsCorp" id="irsTreatsLLCAsCorpNo" checked={this.state.irsTreatsLLCAsCorp === 'no'} onChange={() => this.setState({ irsTreatsLLCAsCorp: 'no' })} />
                            <label className="form-check-label" htmlFor="irsTreatsLLCAsCorpNo">
                                No
                            </label>
                        </div>
                        <div className="form-check">
                            <input className="form-check-input" type="radio" name="irsTreatsLLCAsCorp" id="irsTreatsLLCAsCorpSCorp" checked={this.state.irsTreatsLLCAsCorp === 's-corp'} onChange={() => this.setState({ irsTreatsLLCAsCorp: 's-corp' })} />
                            <label className="form-check-label" htmlFor="irsTreatsLLCAsCorpSCorp">
                                The company is taxed as an S-Corp
                            </label>
                        </div>
                        <div className="form-check">
                            <input className="form-check-input" type="radio" name="irsTreatsLLCAsCorp" id="irsTreatsLLCAsCorpCCorp" checked={this.state.irsTreatsLLCAsCorp === 'c-corp'} onChange={() => this.setState({ irsTreatsLLCAsCorp: 'c-corp' })} />
                            <label className="form-check-label" htmlFor="irsTreatsLLCAsCorpCCorp">
                                The company is taxed as a C-Corp
                            </label>
                        </div>
                    </div>
                )}

                {this.state.entityType !== 'Sole Proprietor' && (
                    <>
                        <div className="form-group">
                            <label htmlFor="legalName">Business Legal Name</label>
                            <input type="text" className="form-control" id="legalName" name="legalName" value={this.state.legalName} onChange={this.handleInputChange} />
                        </div>

                        <div className="form-group">
                            <label htmlFor="dbaName">Doing Business As</label>
                            <input type="text" className="form-control" id="dbaName" name="dbaName" value={this.state.dbaName} onChange={this.handleInputChange} />
                        </div>
                    </>
                )}

                {this.state.entityType === 'Sole Proprietor' && (
                    <div className="form-group">
                        <label htmlFor="soleProprietorName">Sole Proprietor Name</label>
                        <input type="text" className="form-control" id="soleProprietorName" name="soleProprietorName" value={this.state.soleProprietorName} onChange={this.handleInputChange} />
                    </div>
                )}

                <div className="form-group">
                    <label htmlFor="ein">EIN</label>
                    <input type="text" className="form-control" id="ein" name="ein" value={this.state.ein} onChange={this.handleInputChange} />
                </div>

                <div className="form-group">
                    <label htmlFor="sicCode">The company SIC Code</label>
                    <input type="text" className="form-control" id="sicCode" name="sicCode" value={this.state.sicCode} onChange={this.handleInputChange} />
                </div>

                <div className="form-group">
                    <label htmlFor="entityAddress">Entity Address</label>
                    <input typeof="tel" className="form-control" id="entityAddress" name="entityAddress" value={this.state.entityAddress} onChange={this.handleInputChange} />
                </div>

                <div className="form-group">
                    <label htmlFor="phoneNumber">Phone Number</label>
                    <input typeof="tel" className="form-control" id="phoneNumber" name="phoneNumber" value={this.state.phoneNumber} onChange={this.handleInputChange} />
                </div>

                <div className="form-group">
                    <label htmlFor="emailDomain">Work Email Domain Name</label>
                    <input type="text" className="form-control" id="emailDomain" name="emailDomain" value={this.state.emailDomain} onChange={this.handleInputChange} />
                </div>
                <button type="submit" onClick={this.handleSubmit} className="btn btn-primary">Save</button>
            </form>
        );
    }
}

export default ClientOnboardingStepThree;
