import React, { Component } from 'react';
import { getApiData } from '../../../fetchApi'

class PayrollOnboardingStepFour extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedOption: '',
            selectedDeduction: '',
            defaultEmployeeContribution: '',
            defaultEmployerContribution: '',
            annualLimits: {
                individualFilers: '',
                familyFilers: '',
                separateFilers: '',
                employerContribution: '',
                employerContributionWithDependants: '',
            },
            deductionsData: [],
            deductionsSelected: []
        };
    }

    componentDidMount = async () => {
        var apiData = await getApiData("GET_DEDUCTIONS", [])
        var keys = Object.keys(this.props.payrollOnboardingObj)
        var newOnboardingInfo = this.state
        for (var x = 0; x < keys.length; x++) {
            if (newOnboardingInfo[keys[x]] !== undefined) {
                newOnboardingInfo[keys[x]] = this.props.payrollOnboardingObj[keys[x]]
            }
        }
        this.setState({ deductionsData: apiData})
    }

    handleDropdownChange = (event) => {
        this.setState({
            selectedOption: event.target.value, selectedDeduction: event.target.options[event.target.selectedIndex].text, defaultEmployeeContribution: '',
            defaultEmployerContribution: '', });
    };

    handleEmployeeContributionChange = (event) => {
        this.setState({ defaultEmployeeContribution: event.target.value });
    };

    handleEmployerContributionChange = (event) => {
        this.setState({ defaultEmployerContribution: event.target.value });
    };

    handleSave = () => {
        const newDeduction = {
            EmployeeContribution: this.state.defaultEmployeeContribution,
            EmployerContribution: this.state.defaultEmployerContribution,
            Deduction: this.state.selectedDeduction,
            DeductionId: this.state.selectedOption
        };

        this.setState((prevState) => ({
            deductionsSelected: [...prevState.deductionsSelected, newDeduction],
            defaultEmployeeContribution: '',
            defaultEmployerContribution: '',
            selectedOption: '',
            selectedDeduction: ''
        }));
    };

    handleDelete = e => {
        var deductionsSelected = this.state.deductionsSelected
        deductionsSelected = deductionsSelected.filter(x => x.id === e.target.id)
        this.setState({ deductionsSelected: deductionsSelected })
    }

    trackPayrollStep = e => {
        var payrollOnboardInfo = this.state
        this.props.trackOnboardingStep(e.target.id, payrollOnboardInfo)
    }

    renderDeductionsTable() {
        if (this.state.deductionsSelected.length === 0) {
            return null;
        }

        return (
            <div>
                <h5>Added Deductions</h5>
                <table className="table">
                    <thead>
                        <tr>
                            <th>Deduction</th>
                            <th>Default Employee Contribution</th>
                            <th>Default Employer Contribution</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.deductionsSelected.map((deduction) => (
                            <tr key={deduction.DeductionId}>
                                <td>{deduction.Deduction}</td>
                                <td>{deduction.EmployeeContribution}</td>
                                <td>{deduction.EmployerContribution}</td>
                                <td><btn className="btn btn-danger" id={deduction.DeductionId} onClick={this.handleDelete}>Delete</btn></td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        );
    }

    render() {
        return (
           <>
            <div>
                <h3>Setup Deductions</h3>
                <div className="form-group">
                    <label><b>Deduction Types:</b></label>
                    <select
                        className="form-control"
                        value={this.state.selectedOption}
                        onChange={this.handleDropdownChange}
                    >
                        {/* Populate dropdown options from the fetched data */}
                            <option value="">Select a deduction</option>
                            {this.state.deductionsData.map((deduction) => (
                                <option value={deduction.id}>{deduction.deduction}</option>
                            ))}
                        {/* Map over the fetched data to create dropdown options */}
                    </select>
                </div>
                <br />
                {this.state.selectedOption && (
                    <div>
                        <div className="form-group">
                            <label>
                               <b> Would you like to set a default employee monthly contribution? </b>
                            </label> <br />
                                <div style={{ "display": "inline-block" }}>$</div> <div style={{ "display": "inline-block" }}><input
                                type="number"
                                step="0.01"
                                className="form-control"
                                value={this.state.defaultEmployeeContribution}
                                onChange={this.handleEmployeeContributionChange}
                                defaultValue="0"
                            /> </div>
                        </div>
                        <br />
                        <div className="form-group">
                            <label>
                               <b> Would you like to set a default employer monthly contribution? </b>
                            </label> <br />
                                <div style={{ "display": "inline-block" }}>$</div><div style={{ "display": "inline-block" }}><input
                                type="number"
                                step="0.01"
                                className="form-control"
                                value={this.state.defaultEmployerContribution}
                                onChange={this.handleEmployerContributionChange}
                                defaultValue="0"
                            /> </div>
                        </div> <br />
                        <button className="btn btn-primary" onClick={this.handleSave}>
                            Add Deduction
                        </button>
                    </div>
                )}

                {this.renderDeductionsTable()}
                </div>
                 <br />
                <div className="row">
                    <div className="col-lg-2"><button id="continue" onClick={this.trackPayrollStep} className="btn btn-primary">Continue</button></div>
                    <div className="col-lg-2"><button id="back" className="btn btn-warning" onClick={this.trackPayrollStep}>Back</button></div>
                    <div className="col-lg-2"><button onClick={this.props.cancelOnboarding} className="btn btn-danger">Cancel</button></div>
                </div>
            </>
        );
    }
}

export default PayrollOnboardingStepFour;
