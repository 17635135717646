import { Counter } from "./components/Counter";
import { FetchData } from "./components/FetchData";
import { Home } from "./components/Home";
import { Payroll } from "./components/PayrollContent/Payroll";
import { TeamMembers } from "./components/TeamMemberContent/TeamMembers";
import { Admin } from "./components/AdminContent/Admin";

const AppRoutes = [
  {
    index: true,
    element: <Home />
  },
  {
    path: '/counter',
    element: <Counter />
  },
  {
    path: '/fetch-data',
    element: <FetchData />
  },
  {
    path: '/payroll',
    element: <Payroll />
  },
  {
    path: '/people',
    element: <TeamMembers />
  },
  {
    path: '/admin',
    element: <Admin />
  }

];

export default AppRoutes;
