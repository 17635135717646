import React, { Component } from 'react';
import ToDoContent from "./HomePageContent/ToDoContent";
import CalendarContent from "./HomePageContent/CalendarContent";

export class Home extends Component {
    static displayName = Home.name;

    constructor(props) {
        super(props)
        this.state = {
        }
    }

 

  render () {
      return (
          <>
              <div className="row">
                  <div className="col-lg-7">
                      <ToDoContent/>
                  </div>
                  <div className="col-lg-5">
                      <CalendarContent />
                  </div>
              </div>
          </>
    );
  }
}
