import React, { Component } from 'react';

class HiringStepFour extends Component {
    constructor(props) {
        super(props);
        this.state = {
            salary: '',
            payType: '',
            upFrontPayments: ''
        };

        this.handleSalaryChange = this.handleSalaryChange.bind(this);
        this.handlePayTypeChange = this.handlePayTypeChange.bind(this);
        this.handleUpFrontPaymentsChange = this.handleUpFrontPaymentsChange.bind(this);
    }

    componentDidMount = async () => {
        var keys = Object.keys(this.props.newHireObj)
        var newHireInfo = this.state
        for (var x = 0; x < keys.length; x++) {
            if (newHireInfo[keys[x]] !== undefined) {
                newHireInfo[keys[x]] = this.props.newHireObj[keys[x]]
            }
        }

        this.setState({});
    }

    handleSalaryChange(event) {
        this.setState({ salary: event.target.value });
    }

    handlePayTypeChange(event) {
        this.setState({ payType: event.target.value });
    }

    handleUpFrontPaymentsChange(event) {
        this.setState({ upFrontPayments: event.target.value });
    }

    trackHiringStep = e => {
        var hiringInfo = this.state
        this.props.trackHiringStep(e.target.id, hiringInfo)
    }


    render() {
        return (
            <div>
                <form>
                    <div className="row">
                        <div className="col-lg-12">
                            <b>Salary:</b> <br />
                            <input className="form-control" defaultValue={this.state.salary} type="text" onChange={this.handleSalaryChange} />
                        </div>
                    </div>
                    <br />
                    <div className="row">
                        <div className="col-lg-12">
                            <b>Pay Type:</b> <br />
                            <select className="form-control" value={this.state.payType} onChange={this.handlePayTypeChange}>
                                <option value="Hourly">Hourly</option>
                                <option value="Daily">Daily</option>
                                <option value="Weekly">Weekly</option>
                                <option value="Monthly">Monthly</option>
                                <option value="Annually">Annually</option>
                            </select>
                        </div>
                    </div>
                    <br />
                    <div className="row">
                        <div className="col-lg-12">
                            <b>Up Front Payments:</b> <br />
                            <input
                                type="radio"
                                value="yes"
                                checked={this.state.upFrontPayments}
                                onChange={this.handleUpFrontPaymentsChange}
                               
                            />
                            Yes, they will have up front payments <br />
                            <input
                                type="radio"
                                value="no"
                                checked={!this.state.upFrontPayments}
                                onChange={this.handleUpFrontPaymentsChange}
                            />
                            No, they will not have any up front payments
                        </div>
                    </div>
                    <br />
                    <div className="row">
                        <div className="col-lg-2"><a id="save" onClick={this.trackHiringStep} className="btn btn-primary">Save</a></div>
                        <div className="col-lg-2"><button id="back" className="btn btn-warning" onClick={this.trackHiringStep}>Back</button></div>
                        <div className="col-lg-2"><button onClick={this.props.cancelHiring} className="btn btn-danger">Cancel</button></div>
                    </div>
                </form>
            </div>
        );
    }
}

export default HiringStepFour;