import React, { Component } from 'react';
import { getApiData, postApiData } from '../../fetchApi';
import cloneDeep from 'lodash/cloneDeep';


export class EmploymentInfo extends Component {

    constructor(props) {
        super(props)
        this.state = {
            apiData: [],
            editMode: false,
            editData: null
        }
    }

    componentDidMount = async () => {
        this.fetchData()
    }

 

    fetchData = async () => {
        const data = await getApiData("GET_TEAM_MEMBER_DETAILS", [this.props.selectedTeamMemberId])
        this.setState({
            apiData: data
        })
    }

    setEditMode = (e) => {
        var data = this.state.apiData
        e.target.id === 'edit' ? this.setState({ editMode: true, editData: data }) : this.setState({ editMode: false, editData: null })
    }

    save = async (e) => {
        var editData = this.state.editData
        await postApiData("SAVE_TEAM_MEMBER_DETAILS", [editData[0]])
        this.setState({
            apiData: editData,
            editMode: false,
            editData: null
        })
    }

    updateData = (e) => {
        var data = this.state.editData === null ? cloneDeep(this.state.apiData) : cloneDeep(this.state.editData)
        data[0][e.target.id] = e.target.value
        this.setState({ editData: data })
    }

    render() {
        return (
            this.state.apiData.length === 0 ? "" : 
            <>
                <h4>Employment Information </h4>
                <div className="row team-member-info-container">
                    <div className="col-lg-6 team-member-info-header">
                        Legal Entity:
                    </div>
                    <div className="col-lg-6">
                            {
                                this.state.editMode ?
                                    <input type="text" onBlur={this.updateData} id="legalEntity" defaultValue={this.state.editData[0].legalEntity}></input>
                                    :
                                    this.state.apiData[0].legalEntity
                            }
                    </div>
                </div>
                <div className="row team-member-info-container">
                    <div className="col-lg-6 team-member-info-header">
                        Title:
                    </div>
                        <div className="col-lg-6">
                            {
                                this.state.editMode ?
                                    <input type="text" onBlur={this.updateData} id="title" defaultValue={this.state.editData[0].title}></input>
                                    :
                                    this.state.apiData[0].title
                            }
                    </div>
                </div>
                <div className="row team-member-info-container">
                    <div className="col-lg-6 team-member-info-header">
                        Department:
                    </div>
                        <div className="col-lg-6">
                            {
                                this.state.editMode ?
                                    <input type="text" onBlur={this.updateData} id="department" defaultValue={this.state.editData[0].department}></input>
                                    :
                                    this.state.apiData[0].department
                            }
                    </div>
                </div>
                <div className="row team-member-info-container">
                    <div className="col-lg-6 team-member-info-header">
                        Level:
                    </div>
                        <div className="col-lg-6">
                            {
                                this.state.editMode ?
                                    <input type="text" onBlur={this.updateData} id="memberLevel" defaultValue={this.state.editData[0].memberLevel}></input>
                                    :
                                    this.state.apiData[0].memberLevel
                            }
                    </div>
                </div>
                <div className="row team-member-info-container">
                    <div className="col-lg-6 team-member-info-header">
                        Teams:
                    </div>
                        <div className="col-lg-6">
                            {
                                this.state.editMode ?
                                    <input type="text" onBlur={this.updateData} id="teams" defaultValue={this.state.editData[0].teams}></input>
                                    :
                                    this.state.apiData[0].teams
                            }
                    </div>
                </div>
                <div className="row team-member-info-container">
                    <div className="col-lg-6 team-member-info-header">
                        Work Email:
                    </div>
                        <div className="col-lg-6">
                            {
                                this.state.editMode ?
                                    <input type="text" onBlur={this.updateData} id="workEmail" defaultValue={this.state.editData[0].workEmail}></input>
                                    :
                                    this.state.apiData[0].workEmail
                            }
                    </div>
                </div>
                <div className="row team-member-info-container">
                    <div className="col-lg-6 team-member-info-header">
                        Manager:
                    </div>
                        <div className="col-lg-6">
                            {
                                this.state.editMode ?
                                    <input type="text" onBlur={this.updateData} id="manager" defaultValue={this.state.editData[0].manager}></input>
                                    :
                                    this.state.apiData[0].manager
                            }
                    </div>
                </div>
                <div className="row team-member-info-container">
                    <div className="col-lg-6 team-member-info-header">
                        Work Location:
                    </div>
                        <div className="col-lg-6">
                            {
                                this.state.editMode ?
                                    <input type="text" onBlur={this.updateData} id="workLocation" defaultValue={this.state.editData[0].workLocation}></input>
                                    :
                                    this.state.apiData[0].workLocation
                            }
                    </div>
                 </div>
                 <div className="row team-member-info-container">
                        <div className="col-lg-6">
                            {
                                this.state.editMode ?
                                    <>
                                        <a id="save" style={{ marginRight: '10px' }} onClick={this.save} className="btn btn-primary">Save</a>
                                        <a id="cancel" onClick={this.setEditMode} className="btn btn-warning">Cancel</a>
                                    </>
                                    :
                                    <a id="edit" onClick={this.setEditMode} className="btn btn-primary">Edit</a>
                            }
                        </div>
                 </div>
            </>
       );
    }
}

export default EmploymentInfo
