import React, { Component } from 'react';
import { postApiData } from '../../fetchApi';
import './Contact-Us.css'
import './nicepage.css'

export class ContactUs extends Component {

    constructor(props) {
        super(props);
        this.containerRef = React.createRef()
        this.state = {
            htmlContent: '',
            isMessageSent: false
        };
    }

    componentDidMount = () => {
        fetch('/StaticHTMLContent/Contact-Us.html', { headers: { 'Content-Type': 'text/html; charset=utf-8' } })
            .then(response => response.text())
            .then(html => {
                this.setState({ htmlContent: html });
            });

    }

    componentDidUpdate = () => {
        const formEl = this.containerRef.current.querySelector('form');
        formEl.addEventListener('submit', this.handleContactSalesClick);
    }

    handleContactSalesClick = (e) => {
        e.preventDefault();
        const formData = new FormData(e.target)
        var contactUsObj =
        {
            fullName: formData.get("name"),
            email: formData.get("email"),
            message: formData.get("message")
        }
        postApiData("CONTACT_US", [contactUsObj])
        this.setState({ isMessageSent: true })
    };


  render() {
      return (
          <>
              {this.state.isMessageSent ? <div>Thank you for your message! Sales will reach out to see shortly</div> : ""}
              <div dangerouslySetInnerHTML={{ __html: this.state.htmlContent }} ref="contactUsButton" ref={this.containerRef}></div>
          </>
    );
  }
}

export default ContactUs;