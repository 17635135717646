import React, { Component } from 'react';


class HiringComplete extends Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }

    render() {
        return (
           <div>Thanks for onboarding payroll</div>
               
        );
    }
}

export default HiringComplete;