import React, { Component } from 'react';
import { Navbar, NavbarBrand, NavLink } from 'reactstrap';
import './NavMenu.css';

export class Navigation extends Component {

    constructor(props) {
        super(props);
        this.state = {
           
        };
    }

    
    setNavItem = (e) =>
    {
    this.props.setNavigation(e.target.id)
    }  

  render() {
    return (
        <Navbar className="navbar-expand-sm navbar-toggleable-sm ng-white border-bottom box-shadow mb-3" container light>
            <img style={{width:'10%'}} src="/StaticHTMLContent/images/2.png"></img>
            <NavLink onClick={this.setNavItem} id="Home">Home</NavLink>
            <NavLink onClick={this.setNavItem} id="ContactUs">Contact Us</NavLink>
            <NavLink onClick={this.setNavItem} id="Pricing" >Pricing</NavLink>
            <NavLink><btn id="Login" onClick={this.setNavItem} className="btn btn-primary">Sign in</btn></NavLink>
        </Navbar>
    );
  }
}

export default Navigation;