import React, { Component } from 'react';
import CustomerBankingInfo from '../CustomerBankingInfo'
import SignatureCanvas from '../SignatureCanvas'
import { postApiData } from '../../../../fetchApi';

class OnboardingStepFour extends Component {
    constructor(props) {
        super(props);

        this.state = {
            nickname: '',
            fullName: '',
            routingNumber: '',
            accountNumber: '',
            accountType: '',
            bankName: '',
            formError: '',
            guid: this.props.guid
        };
    }

    handleInputChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }

    handleRoutingNumberChange = async (event) => {
        const routingNumber = event.target.value;
        this.setState({
            routingNumber: routingNumber
        });
        if (routingNumber.length === 9) {
            var apiResponse = await fetch('https://www.routingnumbers.info/api/data.json?rn=' + event.target.value)
            var json = await apiResponse.json()
            this.setState({
                routingNumber: routingNumber,
                bankName: json.customer_name
            });
        }
    }

    saveBankingInfo = async () => {
        await postApiData("SAVE_TEAMMEMBER_OWNER_BANKING_INFO", [this.state])
        this.props.trackOnboardingStep()
    }


    render() {
        return (
            <>
                <form onSubmit={this.handleSubmit}>
                    <label>
                        Nickname (optional):
                        <input className="form-control" type="text" name="nickname" value={this.state.nickname} onChange={this.handleInputChange} />
                    </label>
                    <br />
                    <label>
                        Full Name on Account:
                        <input className="form-control" type="text" name="fullName" value={this.state.fullName} onChange={this.handleInputChange} required />
                    </label>
                    <br />
                    <label>
                        Routing number:
                        <input className="form-control" type="text" name="routingNumber" value={this.state.routingNumber} onChange={this.handleRoutingNumberChange} required />
                    </label>
                    {this.state.bankName && <div>Bank Name: {this.state.bankName}</div>}
                    <br />
                    <label>
                        Account number:
                        <input className="form-control" type="text" name="accountNumber" value={this.state.accountNumber} onChange={this.handleInputChange} required />
                    </label>
                    <br />
                    <label>
                        Account Type:
                        <select className="form-control" name="accountType" value={this.state.accountType} onChange={this.handleInputChange} required>
                            <option value="">Select Account Type</option>
                            <option value="savings">Savings</option>
                            <option value="checking">Checking</option>
                        </select>
                    </label>
                </form>
                <br />
                <SignatureCanvas signatureType="_bankingSignature" guid={this.props.guid} saveBankingInfo={this.saveBankingInfo} ></SignatureCanvas>
            </>
        );
    }
}

export default OnboardingStepFour;
